import React from 'react';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { useCompany } from '@/contexts/company/CompanyContext';
import { useMember } from '@/contexts/member/MemberContext';
import { useTranslation } from 'react-i18next';
import TabContainer from '@/components/wrappers/TabContainer';
import ImageOptions from './partials/ImageOptions';
import AvailableImages from './partials/AvailableImages';

interface ImageContainerProps { };

const ImageContainer: React.FC<ImageContainerProps> = ({ }) => {
	const { t: c } = useTranslation('canvas.translation');
	const { member } = useMember();

	const {
		state,
		shapeInfo,
		isEditing,
		getElementDataById
	} = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const { current } = state;

	const { company } = useCompany();

	const updateImage = (src: string | null) => {
		const data = getElementDataById(current.id);
		if (current.id && data && data.attributes && data.ref && shapeInfo.isImage) {
			const attributes = data.attributes;
			stateDispatch({
				type: "updateElement",
				payload: {
					id: current.id,
					attributes: {
						...attributes,
						image: src
					}
				}
			});
		}
		else console.log('image not found')
	}

	const applyLogo = (logo: string | null, index: number) => {
		if ((index < 1 || index > 3) || !company) return;

		const data = getElementDataById(current.id);
		if (current.id && data && data.attributes && data.ref && shapeInfo.isImage) {
			const attributes = data.attributes;
			stateDispatch({
				type: "updateElement",
				payload: {
					id: current.id,
					attributes: {
						...attributes,
						image: logo,
						imageType: `logo_${index}` as 'logo_1' | 'logo_2' | 'logo_3'
					}
				}
			});
		}
	}

	const tabs = [
		{
			id: 'tab1',
			label: 'Publicadas',
			content: <AvailableImages {...{ member, updateImage, applyLogo }} />,
		}
	];

	if (isEditing) {
		tabs.push({
			id: 'tab2',
			label: 'Mais Opções',
			content: <ImageOptions {...{ member, updateImage, applyLogo }} />,
		});
	}

	return (
		<div className='overflow-x-hidden h-full'>
			{
				current.type === 'image' ? (
					<TabContainer tabs={tabs} />
				) : (
					<>
						<h2 className="w-full text-center text-2xl" >{c("editor.images.label")}:</h2>
						<h3 className="w-full text-center text-md mt-10" >Selecione ou adicione um elemento de imagem</h3>
					</>
				)
			}
		</div>
	);
}

export default ImageContainer;
