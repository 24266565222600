import React, { useEffect } from "react";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { Sections } from "@/types/canvas";
import { freeSet } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import TextContainer from "@/components/editor/sidebar/TextContainer";
import FormsContainer from './sidebar/FormsContainer';
import ProductsContainer from "./sidebar/ProductsContainer";
import LayoutContainer from "./sidebar/LayoutContainer";
import EditorPreferencesContainer from "./sidebar/EditorPreferencesContainer";
import ImageContainer from "./sidebar/ImageContainer";
import useScreenDimensions from "@/hooks/useScreenDimensions";
import { useModals } from "@/contexts/modals/ModalsContext";
import MouseOverPopover from "../buttons/MouseOverPopover";

interface SidebarProps { }

const EditorSidebar: React.FC<SidebarProps> = () => {
    const { isLargeScreen } = useScreenDimensions();
    const { state, setTemplateType } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();
    const { editor } = state;
    const isEditing = editor.viewMode === 'edit';

    useEffect(() => {
        setTemplateType();
        // Garante que só as tabs permitidas são acessíveis.
        const allowedTabs: Array<Sections> = ['layout', 'texts', 'products', 'images'];
        if (!allowedTabs.includes(editor.section) && !isEditing) {
            stateDispatch({ type: "updateSection", section: 'products' });
        }
    }, [editor, isEditing]);

    return (
        <>
            <div className={`flex flex-row min-w-2/3 h-full z-50`} >
                <div className={`w-18 h-full flex flex-col justify-between bg-white`}>
                    <div className={`flex flex-col ${!isLargeScreen && 'justify-center'} h-full border-r-2 border-r-gray-200`}>
                        <TabButton label="Modelos" icon={freeSet.cilColumns} section={'layout'} />

                        <TabButton label="Produtos" icon={freeSet.cilCart} section={'products'} />
                        <TabButton label="Textos" icon={freeSet.cilTextShapes} section={'texts'} />
                        <TabButton label="Imagens" icon={freeSet.cilImagePlus} section={'images'} />

                        {isEditing && <TabButton label="Opções" icon={freeSet.cilSettings} section={'preferences'} />}
                        {!isLargeScreen && <TabButton label="Fechar" icon={freeSet.cilX} section={'home'} />}
                    </div>
                </div>
                <div className="w-full flex items-center justify-center">
                    <div className={"w-full h-full overflow-y-auto dark:text-white bg-secondary"}>
                        <MenuList />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditorSidebar;

type TabBtn = {
    label: string;
    section: Sections | 'home';
    icon: string | string[];
}

export function TabButton({ label, icon, section }: TabBtn) {
    const baseClassName = "flex flex-col w-full items-center text-gray-500 justify-center hover:text-orange-500 cursor-pointer p-2 my-2 duration-300 rounded";
    const { isLargeScreen } = useScreenDimensions();
    const { setShowCanvasModal } = useModals();

    const { state } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();
    const { editor } = state;

    const onClick = () => section === 'home' ? setShowCanvasModal(false) : stateDispatch({ type: "updateSection", section });

    return (
        <>
            {
                isLargeScreen ? (
                    <div onClick={onClick} className={`${baseClassName} ${editor.section === section ? 'text-orange-500' : 'text-gray-500'} `}>
                        <CIcon icon={icon} width={20} size="xxl" />
                        {isLargeScreen && label}
                    </div>
                ) : (
                    <MouseOverPopover popoverText={label}>
                        <div onClick={onClick} className={`${baseClassName} ${editor.section === section ? 'text-orange-500' : 'dark:text-gray-500'} `}>
                            <CIcon icon={icon} width={20} size="xxl" />
                            {isLargeScreen && label}
                        </div>
                    </MouseOverPopover>
                )
            }
        </>
    )
}

const MenuList: React.FC = () => {
    const { state } = useCanvas();

    return (
        <div className={"h-full overflow-y-auto overflow-x-hidden scrollbar-hide"}>
            {state.editor.section === 'layout' && <LayoutContainer />}
            {state.editor.section === 'forms' && <FormsContainer />}
            {state.editor.section === 'texts' && <TextContainer />}
            {state.editor.section === 'products' && <ProductsContainer />}
            {state.editor.section === 'images' && <ImageContainer />}
            {state.editor.section === 'preferences' && <EditorPreferencesContainer />}
        </div>
    );
}
