import { useCanvas } from "@/contexts/editor/CanvasContext";
import { useMember } from "@/contexts/member/MemberContext";
import { useSaveTemplate } from "@/contexts/modals/saveTemplate/SaveTemplateContext";
import { PrivacyOptions } from "@/types/canvas";
import { DropdownList } from "react-widgets/cjs";
import Accordion from "./CompanyAcordion";

export const RestrictVisibility = () => {
    const { member } = useMember();
    const { isLoadingTemplateData } = useCanvas();
    const { privacyOption, setPrivacyOption } = useSaveTemplate();

    type ICompanyData = {
        id: string | number;
        label: string;
    }

    function getPrivacyOptions(): ICompanyData[] {
        return [
            { id: "private", label: "Apenas minha Empresa" },
            { id: "restrict", label: "Restringir Visibilidade." },
            { id: "public", label: "Público" },
        ];
    }

    const companySelect = () => {
        return (
            <div className="h-full w-full flex flex-col items-center justify-center overflow-auto">
                {
                    (privacyOption === 'restrict') && (
                        <Accordion />
                    )
                }
            </div>
        );
    }

    return (
        <>
            <div className="w-full flex flex-col items-center justify-center py-4 text-zinc-500">
                <h2 className="font-bold text-zinc-700">Visibilidade</h2>
                <p>Defina quem poderá ter acesso ao template que você criou.</p>
                <div className="w-full flex flex-col items-center justify-center gap-10 mt-4 mb-4">
                    <div className="w-3/6 flex flex-col items-center">
                        <DropdownList id="privacy-selector"
                            disabled={isLoadingTemplateData}
                            filter={false}
                            value={privacyOption}
                            onChange={(option) => setPrivacyOption(option.id as PrivacyOptions)}
                            data={getPrivacyOptions()}
                            dataKey="id"
                            textField="label"
                            className="cursor-default"
                        />
                    </div>
                </div>
                <span className={`text-lg font-semibold mb-2 ${privacyOption === 'restrict' ? "" : "hidden"}`}>
                    Restrição
                </span>
                <div className="w-full h-[21rem] overflow-auto">
                    {companySelect()}
                </div>
            </div>
        </>
    );
}