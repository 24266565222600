import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { SetterName } from '@/contexts/editor/DynamicPromoContext';

interface PromoTextAssociationSelectInterface {
	onChange: (value: SetterName | undefined) => void,
	defaultValue?: SetterName | null,
	isClearable?: boolean,
	disabled?: boolean,
}

type FieldOption = { value: SetterName, label: string };

export const __FIELD_OPTIONS__: Array<FieldOption> = [
	{ value: "cpf_limit_enabled", label: "Limite de CPF" },
	{ value: "date_limit_enabled", label: "Data Limite" },
	{ value: "expiration_date_enabled", label: "Data de Validade" },
	{ value: "price_a_cada_enabled", label: "Preço A Cada" },
	{ value: "ap_x_UN_enabled", label: "AP X Unid." },
	{ value: "xOff_xUN_enabled", label: "xOff X Unid." },
	{ value: "compre_e_ganhe_enabled", label: "Compre e Ganhe" },
	{ value: "leveX_pagueY_enabled", label: "Leve X Pague Y" },
	{ value: "maisX_Leve_enabled", label: "Mais X Leve" },
	{ value: "compreX_MaisX_Leve_enabled", label: "Compre X Mais X Leve" },
	{ value: "installments_enabled", label: "Preço Parcelado" },
];

const PromoTextAssociationSelect: React.FC<PromoTextAssociationSelectInterface> = ({ onChange, defaultValue = null, isClearable = true, disabled = true }) => {	
	const defaultOption = __FIELD_OPTIONS__.find(opt => opt.value === defaultValue);
	const [value, setValue] = useState(defaultOption);

	const _onChange = React.useCallback((option: FieldOption | undefined) => {
		const value = option?.value as SetterName ?? undefined;
		setValue(option);
		onChange(value);
	}, [onChange]);

	useEffect(() => {
		const option = __FIELD_OPTIONS__.find(opt => opt.value === defaultValue);
		setValue(option);
	}, [defaultValue]);

	return (
		<div className='w-full flex'>
			<Dropdown
				options={__FIELD_OPTIONS__}
				// @ts-ignore
				onChange={_onChange}
				value={value}
				placeholder="Escolha um campo"
				disabled={disabled}
				className='w-full'
			/>
			<span onClick={() => _onChange(undefined)} className='flex items-center ml-2 px-2.5 fill-rose-500 cursor-pointer'>
				<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
					<path d="M24 21h-17l-7-7.972 7-8.028h17v16zm-16.09-14l-5.252 6.023 5.247 5.977h14.095v-12h-14.09zm6.09 4.586l2.586-2.586 1.414 1.414-2.586 2.586 2.586 2.586-1.414 1.414-2.586-2.586-2.586 2.586-1.414-1.414 2.586-2.586-2.586-2.586 1.414-1.414 2.586 2.586z"/>
				</svg>
			</span>
		</div>
	);
};

export default PromoTextAssociationSelect;
