import React from 'react';
import { freeSet } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { useCanvas } from '@/contexts/editor/CanvasContext';

type Props = {
	align: string | null;
	isText: boolean
}

const TextAlignmentSelector = ({ align, isText }: Props) => {
	return (
		<div className="inline-flex h-fit w-fit justify-center border border-white hover:border-zinc-200 rounded-md overflow-hidden" >
			<Icon icon={freeSet.cilAlignLeft} value='left' current={align} isText={isText} />
			<Icon icon={freeSet.cilAlignCenter} value='center' current={align} isText={isText} />
			<Icon icon={freeSet.cilAlignRight} value='right' current={align} isText={isText} />
		</div>
	)
}

type IconProps = {
	value: 'center' | 'left' | 'right';
	icon: string | string[];
	current: string | null;
	isText: boolean;
}
const Icon = ({ value, icon, current, isText }: IconProps) => {
	const { updateTextAlign } = useCanvas();
	const isSelected = isText && value === current;
	return (
		<div className='w-full h-full flex justify-center' >
			<CIcon icon={icon} onClick={() => updateTextAlign(value)} width={20}
				size="xxl" className={`w-10 rounded-md h-full p-2 cursor-pointer ${isText ? "text-gray-600" : "cursor-not-allowed text-zinc-300"} ${isSelected && 'bg-blue-500 text-white'}`} />
		</div>
	);
}

export default TextAlignmentSelector;
