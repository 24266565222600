import { useCanvas } from '@/contexts/editor/CanvasContext';
import { useFontContext } from '@/contexts/fonts/FontContext';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

interface FontFamilyOptionsInterface {
	defaultValue?: FamilyOptions | null,
	disabled?: boolean,
}

export type FamilyOptions = "Anton" | "BigShotOne" | "DotGothic16" | "Grenze"
	| "HelveticaNeue" | "HelveticaNeueBold" | "MajorMonoDisplay" | "Montserrat"
	| "MercadilloBlack" | "OleoScript" | "Pacifico" | "Roboto" | "RumRaisin"
	| "RussoOne" | "Sofia";

type FieldOption = { value: FamilyOptions, label: string };

const FontFamilyOptions: React.FC<FontFamilyOptionsInterface> = ({ defaultValue = null, disabled = true }) => {
	const [selectedField, setSelectedField] = useState<FamilyOptions | null>(defaultValue);

	const ref = useRef(null);

	const { fonts } = useFontContext();

	const __FONT_OPTIONS__: Array<FieldOption> = fonts.map((font) => ({ value: font.id as FamilyOptions, label: font.label }));

	const { updateFontFamily } = useCanvas();

	const _onChange = (value: FamilyOptions | null) => {
		if (value) {
			setSelectedField(value);
			updateFontFamily(value || '');
		}
	}

	useEffect(() => {
		setSelectedField(defaultValue);
		if (ref.current) {
			const currentFont = __FONT_OPTIONS__.find((option) => option.value === defaultValue);
			if (currentFont) {
				// @ts-ignore
				ref.current.commonProps.setValue(currentFont);
			} else {
				// @ts-ignore
				ref.current.commonProps.setValue("");
			}
		}
	}, [defaultValue]);

	return (
		<Select<FieldOption>
			ref={ref}
			isDisabled={disabled}
			isClearable={true}
			menuPlacement="auto"
			noOptionsMessage={() => 'Campo'}
			options={__FONT_OPTIONS__}
			placeholder="Escolha um campo"
			onChange={(option) => _onChange(option?.value ?? null)}
			defaultValue={__FONT_OPTIONS__.find((option) => option.value === selectedField)}
			isSearchable={false}
			styles={{
				control: (provided, state) => ({
					...provided,
					borderColor: state.isFocused ? '#ddd' : '#ccc',
					boxShadow: 'none', // Remove focus shadow
					'&:hover': {
						borderColor: '#ccc'
					}
				}),
				option: (provided, state) => ({
					...provided,
					fontFamily: state.data.value,
				}),
				clearIndicator: (provided, state) => ({
					...provided,
					display: "none"
				}),
				indicatorsContainer: (provided, state) => ({}),
			}}
		/>
	);
};

export default FontFamilyOptions;
