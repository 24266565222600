import React from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useModals } from "@/contexts/modals/ModalsContext";
import ResultTable from "./ResultTable";
import SearchBar from "./SearchBar";
import SearchGlobalType from "@/components/partials/products/SearchGlobalType";

interface ProductSearchModalInterface { }

const ProductSearchModal: React.FC<ProductSearchModalInterface> = () => {
	const { searchProductModal, setSearchProductModal } = useModals();

	const closeModal = () => setSearchProductModal(false);

	return (
		<HandleModal className="rounded-2xl px-0" isOpen={searchProductModal} onClose={closeModal}>
			<div className="relative h-[84vh] w-fit flex flex-col">
				<div className="w-full flex flex-col justify-center items-center">
					<h2 className="text-2xl font-bold mb-2 text-center">Produtos de Hoje</h2>
					<small className="mb-2 text-center text-gray-400">Adicione aqui os produtos que irá usar hoje</small>
				</div>
				<div className="flex flex-col items-center justify-center">
					<SearchGlobalType />
					<SearchBar campaigns={[]} />
				</div>
				<div className="min-w-[95vw] max-w-[95vw] overflow-y-auto">
					<ResultTable />
				</div>
				<button className="absolute top-0 right-5" onClick={closeModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="fill-gray-300 hover:fill-gray-500" width="15" height="15" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"></path></svg>
				</button>
			</div>
		</HandleModal>
	);
};

export default ProductSearchModal;
