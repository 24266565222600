import DropdownCampaignsOptions from "@/components/buttons/DropDownCampaignsOptions";
import { useSearch } from "@/contexts/products/SearchContext";
import { Campaign } from "@/types/campaigns";
import { useEffect, useState } from "react";

interface ISearchField {
    campaigns: Campaign[];
}

const SearchField: React.FC<ISearchField> = ({ campaigns }) => {
    const {
        selectedCampaign,
        productSearch,
        currentPage,
        setCurrentPage,
    } = useSearch();
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => { productSearch(searchTerm); }, [currentPage]);

    const isNotSelectedCampaign = selectedCampaign === undefined;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setCurrentPage(1);
            productSearch(searchTerm);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <div className="flex gap-2">
                <DropdownCampaignsOptions
                    campaigns={campaigns}
                />
                <div className="flex w-full">
                    <input
                        type="text"
                        id="Pesquisar"
                        className={`
                        bg-white rounded-l-lg border border-gray-300 focus:outline-none focus:border-orange-500 text-gray-900 text-sm block w-full p-2.5 ${isNotSelectedCampaign && "cursor-not-allowed"}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        placeholder='Pesquisar por "descrição, código-interno ou ean"'
                        disabled={isNotSelectedCampaign}
                        required
                    />
                    <div className={`flex items-center justify-center px-4 bg-app-primary cursor-pointer rounded-r-lg`}
                        onClick={() => {
                            setCurrentPage(1);
                            productSearch(searchTerm);
                        }}>
                        <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchField;