import React from "react";
import HandleModal from "@/components/modals/BlurModal";

interface DangerConfirmationModalInterface {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string | React.ReactNode | React.ReactNode[];
    closeOnConfirm?: boolean;
    rejectButton?: string;
    confirmButton?: string;
}

const DangerConfirmationModal: React.FC<DangerConfirmationModalInterface> = ({ isOpen, onClose, onConfirm, message, closeOnConfirm = true, rejectButton = 'Não', confirmButton = 'Sim' }) => {
    const buttonsClasses = "mt-2 mx-4 py-1 px-4 rounded hover:scale-105 transition-all duration-300";

    const _onConfirm = () => {
        onConfirm();
        if (closeOnConfirm) onClose();
    }

    return (
        <HandleModal isOpen={isOpen} onClose={onClose} >
            <div className="flex flex-col justify-center items-center text-center">
                <span className="cursor-default no-text-highlight" >
                    {message}
                </span>
                <div className="flex flex-row w-full justify-center mt-4">
                    <button className={`${buttonsClasses} bg-slate-200`} onClick={onClose}>{rejectButton}</button>
                    <button className={`${buttonsClasses} bg-red-500 text-white`} onClick={_onConfirm} >{confirmButton}</button>
                </div>
            </div>
        </HandleModal>
    )
}

export default DangerConfirmationModal;
