import ProductRow from "./ProductRow";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";

interface ProductListInterface {
}

const ProductList: React.FC<ProductListInterface> = () => {
	const { state } = useCanvas();
	const { products } = state;

	return (
		<div className='w-full p-4 xl:px-10 h-full overflow-auto flex flex-col' >
			<ul className='flex flex-col items-center h-full'>
				{
					products.ids.map((productId, index) => <ProductRow id={productId} key={index} pos={index + 1} />)
				}
			</ul>
		</div>
	)
}

export default ProductList;
