import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import React from 'react';
import Dropdown from 'react-dropdown';

interface ProductAttributionOptionsInterface {
	defaultValue?: string | null;
	disabled?: boolean;
}

type FieldOption = { value: string, label: string };

const ProductAttributionOptions: React.FC<ProductAttributionOptionsInterface> = ({ defaultValue = null, disabled = true }) => {
	const { state } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { products, current } = state;

	const getProductsLabelList = () => {
		return products.ids.map((_, index) => `Produto ${index + 1}`);
	}

	const handleProductAttachment = (productID: string | null) => {
		if (current.exists && current.info.isText) {
			stateDispatch({ type: "attachElementToProduct", id: current.id, productId: productID })
			stateDispatch({ type: "selectProduct", id: productID });
		}
	}

	const _onChange = (option: { value: string, label: React.ReactNode }) => {
		if (typeof option.value === "string") {
			handleProductAttachment(option.value);
		}
	}

	const __PRODUCT_OPTIONS__: Array<FieldOption> = getProductsLabelList().map((product, index) => ({ value: products.ids[index], label: product }));
	const defaultOption = __PRODUCT_OPTIONS__.find(opt => opt.label === defaultValue);

	return (
		<div className="w-full">
			<Dropdown
				options={__PRODUCT_OPTIONS__}
				onChange={_onChange}
				value={defaultOption}
				placeholder="Escolha um"
				disabled={disabled}
			/>
		</div>
	);
};

export default ProductAttributionOptions;
