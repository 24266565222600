import React, { useEffect, useState } from "react";
import Api from "@/api/Api";
import { now } from "@/helpers";
import ImageHelper from "@/helpers/ImageHelper";
import { IMember } from "@/types/member";
import { EntityID } from "@/types/silverstripe";
import { useTranslation } from "react-i18next";
import ImageSearchBar from "./ImageSearchBar";
import { useCanvas } from "@/contexts/editor/CanvasContext";
import { ImageType } from "@/types/canvas";
import Loading from "@/components/animations/Loading";

type ImageData = {
	id: EntityID,
	url: string,
	type: NonNullable<ImageType>
}

interface AvailableImagesInterface {
	member: IMember,
	updateImage: (src: string | null) => void
}

const AvailableImages: React.FC<AvailableImagesInterface> = (
	{
		member,
		updateImage
	}
) => {
	const { t: c } = useTranslation('canvas.translation');
	const api = new Api('images');

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [imageList, setImageList] = useState<ImageData[]>([]);

	const {
		getElementDataById,
		shapeInfo
	} = useCanvas();

	const current = getElementDataById(shapeInfo.id);

	// Filtra as imagens com base no elemento selecionado.
	const filteredList: ImageData[] = imageList.filter((image) => {
		// Não exibe nada caso não atenda os requisitos.
		if (!shapeInfo.isImage || !current || !current.attributes.imageType) return false;
		// Exibe todas as imagens para tipo estático.
		else if (current.attributes.imageType === 'static') return true;
		// Exibe apenas imagens do mesmo tipo caso imageType tenha sido setado.
		else return image.type === current.attributes.imageType;
	})

	useEffect(() => {
		getList();
	}, [member]);

	const getList = async () => {
		if (member.isLogged()) {
			setIsLoading(true);
			const images: Array<ImageData> = [];
			const response = await api.post({ hash: member.hash }, 'g');
			if (response.success) {
				const list = response.data.imageList;
				list.forEach((current: any) => {
					images.push({
						id: current.ID,
						url: current.URL,
						type: current.ImageType
					});
				});
				setImageList(images);
			}
			setIsLoading(false);
		}
	}

	const clearSearch = () => {
		getList();
	};

	const search = async (searchTerm: string) => {
		if (!searchTerm) return;
		const request = api.request(member, { term: searchTerm });
		setIsLoading(true);
		const response = await api.post(request, 's');
		if (response.success) {
			const images: Array<ImageData> = [];
			const list = response.data.imageList;
			list.forEach((current: any) => {
				images.push({
					id: current.ID,
					url: current.URL,
					type: current.ImageType
				});
			});
			setImageList(images);
		}
		setIsLoading(false);
	};

	return (
		<>
			<h2 className="w-full text-center text-2xl border-t-2 pt-4" >Imagens Disponíveis:</h2>

			<div className="p-2" >
				<div className="p-2">
					<ImageSearchBar onSearch={search} onClear={clearSearch} />
					{
						isLoading
							? <div className='mt-10 w-full h-full flex items-center justify-center'><Loading /></div>
							: (
								<div className="grid grid-cols-2 gap-1 mt-2">
									{
										filteredList.map((image, index) => (
											<div key={index} className="relative h-64 overflow-hidden rounded-lg shadow-lg hover:bg-slate-100 hover:shadow-2xl duration-300">
												<img alt={`Image ${index + 1}`} height={150} src={ImageHelper.createServerURL(image.url)} className="w-full object-cover" />
												<div className="absolute bottom-1 right-1 p-2 space-x-2 bg-white backdrop-blur-lg backdrop-opacity-80 rounded-lg shadow-lg">
													<button className="text-orange-500 hover:text-orange-700" onClick={() => updateImage(`${ImageHelper.createServerURL(image.url)}?t=${now()}`)}>Aplicar</button>
												</div>
											</div>
										))
									}
								</div>
							)
					}
				</div>
			</div>
		</>
	)
}

export default AvailableImages;
