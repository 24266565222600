import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import { freeSet } from '@coreui/icons';
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import DangerConfirmationModal from "@/components/modals/warnings/DangerConfirmationModal";
import IconButton from "./widgets/IconButton";
import QueueProductDropdown from "@/components/queue/QueueProductDropdown";
import SaveButtonPopover from "@/components/popover/SaveButtonPopover";
import { TabButton } from "@/components/editor/EditorSidebar";
import SaveTemplateModal from "@/components/modals/editor/SaveTemplateModal";
import useScreenDimensions from "@/hooks/useScreenDimensions";
import { useMember } from "@/contexts/member/MemberContext";
import MuiSelect from "../buttons/MuiSelect";
import { ComboType, ElementData, GroupData, Orientation, TemplateType } from "@/types/canvas";
import { EntityID } from "@/types/silverstripe";
import { IMember } from "@/types/member";
import Api from "@/api/Api";
import Notify from "@/helpers/Notify";

interface TopBarProps {
}

type MenuListType = {
    setEraseModalVisibility: (value: boolean) => void;
    setSaveModalVisibility: (value: boolean) => void;
}

type OrientationTypeProp = { label: string, value: NonNullable<Orientation> };
const orientationTypes: Array<OrientationTypeProp> = [
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
    { label: 'Story', value: 'story' },
    { label: 'Post', value: 'post' },
    { label: 'P21', value: 'p21' },
    { label: 'p30', value: 'p30' },
];

const TopBar = ({ }: TopBarProps) => {
    const [eraseModalVisible, setEraseModalVisibility] = useState(false);
    const [restoreModalVisible, setRestoreModalVisibility] = useState(false);
    const [saveModalVisible, setSaveModalVisibility] = useState(false);

    const { loadStateData, state } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();
    const { templateId, templateName } = state;

    const DeleteModalContent = () => {
        return (<>Deseja realmente <b>apagar</b> o layout atual?<br />Esta ação não pode ser desfeita.</>);
    }

    const RestoreModalContent = () => {
        return (<>Deseja realmente <b>restaurar</b> o layout anterior?<br />O conteúdo atual <b>será perdido</b>, deseja continuar?</>);
    }

    const retrieveStoredData = () => {
        if (localStorage !== undefined) {
            const data = localStorage.getItem('generated-cartaz');
            if (data) {
                loadStateData(JSON.parse(data));
            }
            else alert('No data stored');
        }
    }

    const onResetCanvas = () => {
        stateDispatch({ type: "resetCanvas", newValues: { templateId, templateName } });
    }

    return (
        <div className={"flex items-center justify-center h-[4rem] w-full bg-white z-50 border border-t-0 border-zinc-300 rounded-b-lg"}>
            <SaveTemplateModal isOpen={saveModalVisible} onClose={() => setSaveModalVisibility(false)} />
            <DangerConfirmationModal isOpen={eraseModalVisible}
                onClose={() => setEraseModalVisibility(false)} onConfirm={onResetCanvas}
                message={<DeleteModalContent />} />
            <DangerConfirmationModal isOpen={restoreModalVisible}
                onClose={() => setRestoreModalVisibility(false)} onConfirm={retrieveStoredData}
                message={<RestoreModalContent />} />
            <MenuList
                setEraseModalVisibility={setEraseModalVisibility}
                setSaveModalVisibility={setSaveModalVisibility}
            />
        </div>
    )
}

function MenuList({ setEraseModalVisibility, setSaveModalVisibility }: MenuListType) {
    const { member } = useMember();
    const { isSmallScreen } = useScreenDimensions();

    const { stateDispatch } = useCanvasDispatch();
    const { state, isEditing, stateToObject, getStageImageBlob, setIsGeneratingImage, lineID, setLineID } = useCanvas();
    const { templateName, templateType, templateId, elements, editor } = state;
    const { orientation } = editor;

    const [selectedOrientationType, setSelectedOrientationType] = useState<Orientation>(orientation);
    const [isSaving, setSaving] = useState(false);

    const prevOrientationRef = useRef<Orientation>(orientation);
    const prevSelectedOrientationTypeRef = useRef<Orientation>(selectedOrientationType);

    useEffect(() => {
        if (prevOrientationRef.current !== orientation) {
            setSelectedOrientationType(orientation);
        }
        prevOrientationRef.current = orientation;
    }, [orientation]);

    useEffect(() => {
        if (prevSelectedOrientationTypeRef.current !== selectedOrientationType) {
            stateDispatch({ type: "updateOrientation", orientation: selectedOrientationType });
        }
        prevSelectedOrientationTypeRef.current = selectedOrientationType;
    }, [selectedOrientationType]);

    const removeLine = () => {
        stateDispatch({ type: "removeElement", id: lineID });
        setLineID('');
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 's' && event.ctrlKey) {
                if (isEditing) {
                    event.preventDefault();
                    onConfirm();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditing, elements]);

    const onConfirm = () => {
        if (!isEditing) {
            Notify.Error("Por favor, marque a opção de 'editar' e assegure-se de ter permissão para salvar suas alterações.");
            return;
        }

        setSaving(true);
        // Cria o template após a validação do input.
        const postData = async (templateId: string) => {
            const api = new Api('templates', 'ue');
            const data: IData = {
                ...stateToObject(),
                templateId,
                templateName,
                templateType
            }
            const blob = await getStageImageBlob(1); // Usa menor definição para preview.

            setIsGeneratingImage(false);

            if (!blob) {
                Notify.Error("Não foi possível criar a imagem do cartaz.");
                return;
            }

            let templateReq: IPayload;

            templateReq = create_template_request(data, member);

            const request = new FormData();

            templateReq['templateId'] = templateId;

            const { _r } = Api.encryptRequest({ ...templateReq, hash: member.hash });
            request.append('_r', _r);
            request.append('_b', blob);

            api.postBlob(request).then((response) => {
                if (response.success) {
                    stateDispatch({ type: "setTemplateId", templateId: response.data.template.Hash });
                    const message = "Template atualizado com sucesso.";
                    Notify.Success(message);
                }
                else if (response.code === 'template_not_found') {
                    Notify.Error(response.message as string);
                } else if (response.code === 'do_not_has_permission_to_save') {
                    Notify.Error(response.message as string);
                }
                else Notify.Error("Erro ao salvar");
            })
        }
        stateDispatch({ type: "unselect" });
        setIsGeneratingImage(true);
        // Aguarda um pouco para remoção dos transformers.
        setTimeout(() => { postData(templateId as string); setSaving(false) }, 100);
    }

    return (
        <div className="flex justify-between w-full px-3 z-50">
            <div className="flex space-x-2 w-fit">
                {isSmallScreen && <TabButton label="Fechar" icon={freeSet.cilX} section={'home'} />}
                {
                    templateId === null && (
                        <React.Fragment>
                            <MuiSelect title="Papel" options={orientationTypes} selectedValue={selectedOrientationType} setSelectedValue={setSelectedOrientationType} />
                            {
                                elements.length > 1 && (
                                    <IconButton title={'Limpar Template'} content={'Recomeçar'} onClick={() => setEraseModalVisibility(true)} />
                                )
                            }
                        </React.Fragment>
                    )
                }
                {
                    templateId !== null && (
                        <React.Fragment>
                            <div className="flex items-center gap-2">
                                <button className="flex items-center gap-2 bg-green-500 shadow-md shadow-green-500/50 text-white rounded-lg py-2 px-4" onClick={onConfirm} onMouseEnter={removeLine}>
                                    Salvar
                                    <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" /></svg>
                                </button>
                                {
                                    isSaving && (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="fill-orange-500 animate-spin" width="24" height="24" viewBox="0 0 24 24"><path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" /></svg>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>

            <div className="flex items-center space-x-4 w-fit">
                <QueueProductDropdown />
                {(isEditing || member.can('manage_island') || member.can('send_to_any_queue')) && (
                    <SaveButtonPopover setSaveModalVisibility={setSaveModalVisibility} />
                )}
            </div>
        </div>
    );
}

export default TopBar;

type IData = {
    templateId: string;
    templateName: string | null;
    templateType: TemplateType;
    orientation?: Orientation | undefined;
    elements?: ElementData[] | undefined;
    products?: string[] | undefined;
    groups?: GroupData[] | undefined;
    priceType?: string | null;
    comboType?: ComboType | null;
}

interface IPayload {
    payload: {
        CreatorID: EntityID,
        Hash: string | null,
        Name: string | null,
        Orientation: string | undefined,
        Elements: string,
        Products: string,
        Groups: string,
        Type: string,
        PriceType: string | null | undefined,
        ComboType: ComboType | null | undefined
    }
    templateId?: string
}

function create_template_request(data: IData, member: IMember): IPayload {
    const ObjectPayload: IPayload = {
        payload: {
            CreatorID: member.memberID,
            Hash: data.templateId,
            Name: data.templateName,
            Orientation: data.orientation,
            Elements: JSON.stringify(data.elements),
            Products: JSON.stringify(data.products),
            Groups: JSON.stringify(data.groups),
            Type: data.templateType,
            PriceType: data.priceType,
            ComboType: data.comboType
        }
    }

    return ObjectPayload
}