import Api from "@/api/Api";
import MuiSelect from "@/components/buttons/MuiSelect";
import TextInput from "@/components/inputs/TextInput";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { useMember } from "@/contexts/member/MemberContext";
import { useSaveTemplate } from "@/contexts/modals/saveTemplate/SaveTemplateContext";
import { uuid } from "@/helpers";
import Notify from "@/helpers/Notify";
import { ModalInterface } from "@/types";
import { PriceType, PrivacyOptions, ReducedCanvasState, TemplateType } from "@/types/canvas";
import { IMember } from "@/types/member";
import { EntityID } from "@/types/silverstripe";
import React, { useEffect, useState } from "react";

interface SaveTemplateModalInterface extends ModalInterface { }

type SaveMode = "new" | "override";

export const SaveTemplateOptions: React.FC<SaveTemplateModalInterface> = ({ isOpen, onClose }) => {
	const { member } = useMember();
	const {
		state,
		stateToObject,
		getStageImageBlob,
		isLoadingTemplateData,
		setIsLoadingTemplateData,
		setIsGeneratingImage,
		setTemplateType,
		setLineID,
		lineID,
	} = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { templateId, templateName, templateType, campaignId, campaignName } = state;

	const [createNewTemplate, setCreateNewTemplate] = useState(true);
	const [saveMode, setSaveMode] = useState<SaveMode>(createNewTemplate ? 'new' : 'override');
	const { privacyOption, selectedConstraint } = useSaveTemplate();
	const [inputHasError, setInputHasError] = useState(Boolean(templateName));

	type ImageTypeProp = { label: string, value: SaveMode };
	const imageTypes: Array<ImageTypeProp> = [
		{ label: 'Atualizar', value: 'override' },
		{ label: 'Novo Modelo', value: 'new' },
	];

	const closeModal = () => {
		onClose();
		setIsLoadingTemplateData(false);
	}

	const handleSaveMode = (value: SaveMode) => {
		setCreateNewTemplate(value === 'new');
		setSaveMode(value);
	}

	const handleTemplateType = (value: TemplateType) => {
		stateDispatch({ type: "setTemplateType", templateType: value })
	}

	const handleTemplateNameChange = (name: string) => {
		setInputHasError(!name);
		stateDispatch({ type: "setTemplateName", templateName: name });
	}

	const templatesTypes: Array<{ label: string, value: TemplateType }> = [
		{ label: 'Cartaz', value: 'cartaz' },
		{ label: 'Tablóide', value: 'tabloide' },
	];

	useEffect(() => {
		// setCreateNewTemplate(!Boolean(templateId));
	}, [templateId]);

	useEffect(() => {
		setSaveMode(createNewTemplate ? 'new' : 'override');
	}, [createNewTemplate]);

	useEffect(() => {
		stateDispatch({ type: "removeElement", id: lineID });
		setLineID('');
		if (isOpen) {
			setTemplateType();
			setInputHasError(!templateName);
		}
	}, [isOpen]);

	const onConfirm = () => {
		// Cria o template após a validação do input.
		const postData = async (templateId: string) => {
			const api = new Api('templates', createNewTemplate ? 'c' : 'e');
			const data = {
				...stateToObject(),
				templateName,
				templateId,
				templateType
			}

			const blob = await getStageImageBlob(1); // Usa menor definição para preview.

			setIsGeneratingImage(false);

			if (!blob) {
				Notify.Error("Não foi possível criar a imagem do cartaz.");
				return;
			}

			let templateReq: IPayload;

			if (privacyOption === 'restrict') {
				templateReq = create_template_request(data as ReducedCanvasState, privacyOption, member, selectedConstraint);
			} else {
				templateReq = create_template_request(data as ReducedCanvasState, privacyOption, member);
			}

			const request = new FormData();

			if (createNewTemplate) {
				templateReq['campaignId'] = campaignId;
			}
			else {
				templateReq['templateId'] = templateId;
			}

			const { _r } = Api.encryptRequest({ ...templateReq, hash: member.hash });
			request.append('_r', _r);
			request.append('_b', blob);

			api.postBlob(request).then((response) => {
				if (response.success) {
					stateDispatch({ type: "setTemplateId", templateId: response.data.template.Hash });
					closeModal();
					const message = createNewTemplate ? "Template criado com sucesso!" : "Template atualizado com sucesso.";
					Notify.Success(message);
				}
				else if (response.code === 'template_not_found') {
					Notify.Error(response.message as string);
				}
				else Notify.Error("Erro ao salvar");
			}).finally(() => {
				setIsLoadingTemplateData(false)
			});
		}

		// Validação do input.
		if (!inputHasError) {
			setIsLoadingTemplateData(true);
			let id = uuid();
			if (templateId && !createNewTemplate) {
				id = templateId;
			}
			stateDispatch({ type: "unselect" });

			setIsGeneratingImage(true);
			// Aguarda um segundo para remoção dos transformers.
			setTimeout(() => postData(id), 1000);
		}
	}

	const buttonsClasses = "py-2 px-5 rounded-lg transition-all duration-300";

	return (
		<>
			<div className="w-[60%] h-full flex  items-center justify-center py-4 text-zinc-500">
				<div className="w-full">
					<div className="flex flex-col justify-around items-center mb-5">
						<span className="cursor-default font-semibold no-text-highlight text-xl" >
							Pronto para publicar?
						</span>
						<span>Campanha: <strong>{campaignName}</strong></span>
					</div>
					<div className="flex justify-around items-center mb-5">
						<span className="w-2/5 text-start" >
							Nome do Template:
						</span>
						<div className="w-3/5" >
							<TextInput
								disabled={isLoadingTemplateData}
								defaultValue={templateName ?? ''}
								onChange={(value) => handleTemplateNameChange(value)}
								placeholder="Nome do Template"
								error={inputHasError} />
						</div>
					</div>
					<div className="flex justify-around items-center mb-5">
						<span className="w-2/5 text-start" >
							Salvar Como:
						</span>
						<div className="flex flex-row w-3/5">
							<MuiSelect title="Modo" selectedValue={saveMode} setSelectedValue={handleSaveMode} options={imageTypes} />
						</div>
					</div>
					<div className="flex justify-around items-center mb-5">
						<span className="w-2/5 text-start" >
							Tipo do Cartaz:
						</span>
						<div className="flex flex-row w-3/5">
							<MuiSelect title="Tipo" selectedValue={templateType} setSelectedValue={handleTemplateType} options={templatesTypes} />
						</div>
					</div>
					<div className="flex mt-10 justify-between items-center">
						<button className={`${buttonsClasses} text-zinc-600 bg-zinc-200 hover:bg-zinc-300 `} onClick={closeModal}>Sair</button>
						<button className={`${buttonsClasses} bg-orange-500 hover:bg-orange-600 text-white`} onClick={onConfirm} >Salvar</button>
					</div>
				</div>
			</div>
		</>
	);
}

interface IPayload {
	payload: {
		CreatorID: EntityID,
		Hash: string | null,
		Name: string | null,
		Orientation: string,
		Elements: string,
		Products: string,
		Groups: string,
		Privacy: PrivacyOptions,
		RestrictedMarketID?: number[],
		Type: string,
		PriceType: string | null
	}
	campaignId?: string | null
	templateId?: string
}

function create_template_request(data: ReducedCanvasState, privacyOption: PrivacyOptions, member: IMember, RestrictedArray?: number[]): IPayload {
	const ObjectPayload: IPayload = {
		payload: {
			CreatorID: member.memberID,
			Hash: data.templateId,
			Name: data.templateName,
			Orientation: data.orientation,
			Elements: JSON.stringify(data.elements),
			Products: JSON.stringify(data.products),
			Groups: JSON.stringify(data.groups),
			Privacy: privacyOption,
			Type: data.templateType,
			PriceType: data.priceType
		}
	}

	if (RestrictedArray !== undefined) {
		ObjectPayload.payload.RestrictedMarketID = RestrictedArray;
	}

	return ObjectPayload
}
