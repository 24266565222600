import React from 'react';
import { useModals } from "@/contexts/modals/ModalsContext";

interface ITextHeader {
}

const TextHeader: React.FC<ITextHeader> = () => {
	return (
		<div className="inline-flex list-none w-full border-b-2 py-2 px-4 xl:px-10">
			<div className="flex flex-col w-full">
				<div className="flex flex-row justify-between items-center w-full">
					<span className="font-bold text-2xl">Textos</span>
					<DynamicTextConfigButton />
				</div>
			</div>
		</div>
	)
};

export default TextHeader;

export const DynamicTextConfigButton: React.FC = () => {
	const { setShowDynamicTextModal } = useModals();
	const buttonClasses = "w-fit px-4 bg-blue-500 text-white hover:bg-orange-400" +
		"text-center text-gray-700 rounded-lg cursor-pointer " +
		"py-2 duration-300";

	return (
		<button onClick={() => setShowDynamicTextModal(true)} className={buttonClasses}>
			Texto Promocional
		</button>
	)
}
