import React, { useRef } from 'react';
import { useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { TabContainerRef } from '@/components/wrappers/TabContainer';
import ProductsSearchModal from '@/components/modals/editor/ProductSearchModal';
import { useProducts } from '@/contexts/products/ProductsContext';
import ProductList from '@/components/partials/editor/sidebar/products/ProductList';
import ProductHeader from '@/components/partials/editor/sidebar/products/ProductHeader';

interface ProductsContainerProps { };

const ProductsContainer: React.FC<ProductsContainerProps> = ({ }) => {
	const tabRef = useRef<TabContainerRef | null>(null);

	const { showProductModal, setShowProductModal, resetContext } = useProducts();

	const { stateDispatch } = useCanvasDispatch();

	const onCloseModal = () => {
		setShowProductModal(false);
		resetContext();
	}

	const handleAddProduct = () => {
		stateDispatch({ type: "createProduct" })
		tabRef.current?.changeTab('tab1');
	}

	return (
		<div className='flex flex-col items-center justify-center overflow-x-hidden h-full'>
			<ProductsSearchModal isOpen={showProductModal} onClose={onCloseModal} />
			<ProductHeader {...{ handleAddProduct }} />
			<ProductList />
		</div>
	);
}

export default ProductsContainer;
