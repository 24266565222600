import MouseOverPopover from "@/components/buttons/MouseOverPopover"
import CIcon from "@coreui/icons-react"
import React from "react"

type IconButtonProps = {
    icon?: string | string[],
    title: string,
    onClick?: () => void,
    isActive?: boolean,
    content?: React.ReactNode
}

const IconButton: React.FC<IconButtonProps> = (
    {
        icon,
        title,
        content,
        onClick = () => null,
        isActive = false
    }) => {

    return (
        <React.Fragment>
            <MouseOverPopover
                popoverClass='max-w-10 whitespace-pre-line'
                popoverText={title}
            >
                <span
                    className={`${isActive && 'bg-slate-200'} ${content ? 'w-fit' : 'w-10'} m-1 flex items-center justify-center bg-white shadow rounded-lg hover:bg-zinc-100 dark:text-white duration-300 p-2 cursor-pointer`}
                    onClick={onClick} >
                    {icon && <CIcon className="text-zinc-600" icon={icon} width={20} size="xxl" />}
                    {content}
                </span>
            </MouseOverPopover>
        </React.Fragment>
    )
}

export default IconButton;
