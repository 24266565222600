import React, { useState, ChangeEvent, KeyboardEvent } from 'react';

interface ImageSearchBarProps {
	onSearch: (searchTerm: string) => void;
	onClear: () => void;
}

const ImageSearchBar: React.FC<ImageSearchBarProps> = ({ onSearch, onClear }) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const clearSearch = () => {
		setSearchTerm('');
		onClear();
	};

	const search = () => {
		onSearch(searchTerm);
	};

	const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			search();
		}
	};

	return (
		<div className="relative">
			<div className="w-full flex space-x-3">
				<input
					type="text"
					className="w-full py-2 px-3 rounded-lg border focus:outline-none focus:border-blue-500"
					placeholder="Buscar"
					value={searchTerm}
					onChange={handleSearchChange}
					onKeyPress={handleKeyPress}
				/>
				{
					searchTerm && (
						<button
							className="absolute top-1 right-[7rem] mt-2 mr-2 text-gray-500 hover:text-gray-700 cursor-pointer"
							onClick={clearSearch}
						>
							X
						</button>
					)
				}
				<div className="py-2">
					<button
						className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
						onClick={search}
					>
						Buscar
					</button>
				</div>
			</div>
		</div>
	);	  
};

export default ImageSearchBar;
