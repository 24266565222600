import { ModalInterface } from "@/types";
import HandleModal from "../BlurModal";
import { useProducts } from "@/contexts/products/ProductsContext";
import CloseIcon from "@/components/svg/CloseIcon";
import TrashIcon from "@/components/svg/TrashIcon";
import { ProductListItem } from "@/types/products";
import ClearIcon from "@/components/svg/ClearIcon";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import BooleanInput from "@/components/inputs/BooleanInput";
import { useEffect, useState } from "react";

interface IProductsSelectedModal extends ModalInterface { }

const ProductsSelectedModal: React.FC<IProductsSelectedModal> = ({ isOpen, onClose }) => {
    const { productsPrint, setProductsPrint } = useProducts();
    const { searchProductModal } = useModals();
    const { products: productsModal, setProducts } = useCanvasProducts();

    const [keepAfterGenerateModel, setkeepAfterGenerateModel] = useState<boolean>(false);


    const tbodyTdStyle = "w-2/4 pl-8 text-start";
    const products = !searchProductModal ? productsPrint : productsModal;

    useEffect(() => {
        const keepProducts = localStorage.getItem("keepAfterGenerateModel");

        if (!keepProducts) return setkeepAfterGenerateModel(false);

        setkeepAfterGenerateModel(keepProducts === "true");
    }, []);

    const removeProduct = (product: ProductListItem) => {
        const newProductPrintLIst = productsPrint.filter((prod) => {
            return prod.id !== product.id;
        });

        setProductsPrint([...newProductPrintLIst]);
    }

    const removeAllProducts = () => !searchProductModal
        ? setProductsPrint([])
        : setProducts([]);

    const handleKeepp = () => {
        const toggleValue = !keepAfterGenerateModel;
        setkeepAfterGenerateModel(toggleValue);
        localStorage.setItem('keepAfterGenerateModel', toggleValue.toString());
    }

    return (
        <>
            <HandleModal isOpen={isOpen} onClose={onClose} dontWantPadding={true}>
                <div className="w-[50vw] h-[60vh] lg:w-[60vw] lg:h-[70vh] flex flex-col items-center justify-center">
                    <div className="p-4 w-full flex items-center justify-between">
                        <div className="w-full flex items-center justify-start space-x-10">
                            <button className="flex gap-2 text-rose-500 font-semibold"
                                onClick={removeAllProducts}
                            >
                                <ClearIcon style="w-5 ml-4 stroke-rose-500" />
                                Limpar
                            </button>
                            <div className='flex items-center cursor-default space-x-2 w-50 min-w-fit ml-4'>
                                <span
                                    className='whitespace-no-wrap min-w-fit h-full flex items-center px-2 text-zinc-500 font-medium'>
                                    Quer manter os produtos depois de gerar os cartazes?
                                </span>
                                <BooleanInput defaultValue={keepAfterGenerateModel} onChange={handleKeepp} />
                            </div>
                        </div>
                        <button onClick={onClose}>
                            <CloseIcon style="stroke-zinc-400" />
                        </button>
                    </div>

                    <div className="w-full h-full mb-4 overflow-auto">
                        <table className="w-full">
                            <thead className="bg-zinc-50 text-zinc-500 sticky top-0">
                                <tr >
                                    <th className={"w-2/4 px-8 py-2 text-start"}>Produto</th>
                                    <th className="w-10 text-start">Preco S.</th>
                                    <th className="w-10 text-start">Preco O.</th>
                                    <th className="w-10 text-start">Preco C.</th>
                                    <th className="w-10 text-start">Preco E.</th>
                                    <th className="w-0 text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products.map((product, index) => (
                                        <tr
                                            key={product.id}
                                            className={`${index % 2 === 0 ? 'bg-zinc-100' : 'bg-white'} border-b`}
                                        >
                                            <td className={tbodyTdStyle}>{product.description}</td>
                                            <td className="w-10 py-4">{product.sPrice ? product.sPrice?.trim() : "0.00"}</td>
                                            <td className="w-10 py-4">{product.oPrice ? product.oPrice?.trim() : "0.00"}</td>
                                            <td className="w-10 py-4">{product.cPrice ? product.cPrice?.trim() : "0.00"}</td>
                                            <td className="w-10 py-4">{product.ePrice ? product.ePrice?.trim() : "0.00"}</td>
                                            <td className="w-0 py-4">
                                                <button
                                                    className="p-2 rounded-md bg-rose-500 shadow-md shadow-rose-500/50"
                                                    onClick={() => removeProduct(product)}
                                                >
                                                    <TrashIcon className="stroke-white stroke-[1.7]" />
                                                </button>
                                            </td>
                                        </tr >
                                    ))
                                }
                            </tbody>
                        </table >
                    </div>
                </div>
            </HandleModal >
        </>
    )
}

export default ProductsSelectedModal;