import React, { useEffect, useRef, useState } from 'react';
import useBoolean from '@/hooks/useBoolean';
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import { TemplateListType, TemplateLists, categorizeTemplates } from '@/contexts/models/ModelsContext';
import BannerCard from '@/components/cards/models/BannerCard';
import TextHelper from '@/helpers/TextHelper';
import { Orientation } from '@/types/canvas';
import Select from 'react-select';
import LayoutHeader from './partials/LayoutHeader';

type LayoutContainerProps = {};

type LayoutOption = { label: string; value: string };

interface IList {
	items: TemplateListType[]
}

const initialList: TemplateLists = {
	vertical: [],
	horizontal: [],
	p21: [],
	story: [],
	post: [],
	p30: []
}

const LayoutContainer = ({ }: LayoutContainerProps) => {
	const { member } = useMember();

	const [items, setItems] = useState<TemplateLists>(initialList);
	const fetchedItems = useRef(initialList);

	const [selectedLayout, setSelectedLayout] = useState<LayoutOption | null>(null);
	const [layoutOptions, setLayoutOptions] = useState<LayoutOption[]>([]);
	const [selectedOrientation, setSelectedOrientation] = useState<Orientation>("vertical");
	const { value, on, off } = useBoolean(true);

	useEffect(() => {
		const api = new Api('templates', 'gl');
		const request = api.request(member);
		api
			.post(request)
			.then((response) => {
				if (response.success) {
					const { templateList: list } = response.data;
					const sortedList = categorizeTemplates(list);
					setItems(sortedList);
					fetchedItems.current = sortedList;
				}
			});
	}, []);

	useEffect(() => {
		if (member.isLogged()) {
			(async () => {
				const api = new Api('campaigns');
				const request = api.useHash(member);
				on();
				const response = await api.post(request, 'lac');
				if (response.success) {
					const { data } = response;
					const options = data.map((campaign: any) => ({
						label: campaign.Name,
						value: campaign.ID,
					}));
					setLayoutOptions(options);
					off();
				}
			})();
		}
	}, [member]);

	const handleLayoutChange = (selectedOption: LayoutOption | null) => {
		if (selectedOption) {
			const campaignID = selectedOption.value;

			// Filtrar os itens com base no campaignID
			const filteredItems: TemplateLists = {
				vertical: fetchedItems.current.vertical.filter(item => item.campaignID === campaignID),
				horizontal: fetchedItems.current.horizontal.filter(item => item.campaignID === campaignID),
				p21: fetchedItems.current.p21.filter(item => item.campaignID === campaignID),
				p30: fetchedItems.current.p30.filter(item => item.campaignID === campaignID),
				story: fetchedItems.current.story.filter(item => item.campaignID === campaignID),
				post: fetchedItems.current.post.filter(item => item.campaignID === campaignID),
			};

			// Atualizar o estado
			setItems(filteredItems);

			// Definir o layout selecionado
			setSelectedLayout(selectedOption);
		} else {
			setSelectedLayout(null);
			setItems(fetchedItems.current);
		}
	};

	const CurrentList: Record<Orientation, React.ReactNode> = {
		"vertical": <ContainerList items={items} type='vertical' />,
		"horizontal": <ContainerList items={items} type='horizontal' />,
		"p21": <ContainerList items={items} type='p21' />,
		"p30": <ContainerList items={items} type='p30' />,
		"story": <ContainerList items={items} type='story' />,
		"post": <ContainerList items={items} type='post' />,
	}

	const OrientationButton: React.FC<{ value: Orientation }> = ({ value }) => {
		const active = selectedOrientation === value;
		return (
			<button
				className={`${active
					? 'bg-orange-500 border-b-4 border-orange-600'
					: 'bg-zinc-400'} text-white rounded-lg m-1 py-1 px-2`}
				onClick={() => setSelectedOrientation(value)}>
				{TextHelper.capitalizeFirstLetter(value)}
			</button>
		)
	}

	return (
		<div className='flex flex-col no-text-highlight'>
			<LayoutHeader />

			<div className='flex flex-col mt-2 px-4 xl:px-10'>
				<div className="w-full max-w-10">
					<label htmlFor="searchCampaign" className="text-sm font-medium">Filtrar Campanha</label>
					<div className="flex space-x-1 w-full justify-between items-center">
						<Select
							id="searchCampaign"
							isLoading={value}
							loadingMessage={() => 'Carregando campanhas...'}
							options={layoutOptions}
							value={selectedLayout}
							onChange={handleLayoutChange}
							placeholder="Selecione uma Campanha"
							className="w-full"
							isClearable
						/>
					</div>
				</div>
				<div className='flex flex-col mt-2'>
					<label className="text-sm font-medium">Filtrar Tamanho</label>
					<div className='flex flex-row'>
						<OrientationButton value="vertical" />
						<OrientationButton value="horizontal" />
						<OrientationButton value="p21" />
						<OrientationButton value="p30" />
						<OrientationButton value="story" />
						<OrientationButton value="post" />
					</div>
				</div>
				{CurrentList[selectedOrientation]}
			</div>

		</div>
	)
}

export default LayoutContainer;

const ContainerList: React.FC<{ items: TemplateLists, type: Orientation }> = ({ type, items }) => {
	return (
		<div className='w-full m-auto'>
			<List items={items[type]} />
		</div>
	)
}

const List: React.FC<IList> = ({ items }) => {
	return (
		<div className='no-text-highlight'>
			{
				items.length > 0 && (
					<div className="flex gap-2 flex-col items-center justify-center
					 mt-2 max-w-full overflow-x-auto overflow-y-hidden">
						{
							items.reduce<TemplateListType[][]>((acc, item, index) => {
								if (index % 2 === 0) acc.push([item]);
								else acc[acc.length - 1].push(item);
								return acc;
							}, []).map((pair, index) => (
								<div key={index} className="w-full flex flex-row items-center justify-center">
									{
										pair.map((item, subIndex) => (
											<div key={subIndex} className="w-full flex items-center justify-center">
												{/* @ts-ignore */}
												<BannerCard item={item} type="reload" className='w-full' />
											</div>
										))
									}
								</div>
							))
						}
					</div>
				)
			}
		</div>
	)
}
