
interface SVGIconInterface {
    className?: string;
}

const ArrowDownIcon: React.FC<SVGIconInterface> = ({ className }) => {
    return (
        <svg
            className={`text-white w-2.5 h-2.5 ${className}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
            />
        </svg>
    );
}

export default ArrowDownIcon;