import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import { useMember } from "@/contexts/member/MemberContext";
import Api from "@/api/Api";
import { EntityID } from "@/types/silverstripe";
import Notify from "@/helpers/Notify";
import { useCanvas } from "@/contexts/editor/CanvasContext";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import EpjLoadingIcon from "@/components/animations/EpjLoadingIcon";

interface MarketingSelectorModalInterface extends ModalInterface { }

type MarketItem = {
	name: string,
	entityID: EntityID,
	storeID: string
}

const MarketingSelectorModal: React.FC<MarketingSelectorModalInterface> = ({ isOpen, onClose }) => {
	const { member } = useMember();

	const { getStageImageUri, state } = useCanvas();
	const { editor } = state;

	const { products } = useCanvasProducts();

	const [isLoading, setIsLoading] = useState(true);
	const [list, setList] = useState<Array<MarketItem>>([]);
	const [selectedItems, setSelectedItems] = useState<Array<MarketItem>>([]);

	const closeModal = () => {
		onClose();
		setIsLoading(false);
		setList([]);
		setSelectedItems([]);
	}

	useEffect(() => {
		if (isOpen) {
			const api = new Api('market', 'gl');
			const request = api.request(member, { companyID: member.companyID });
			setIsLoading(true);
			api.post(request).then(response => {
				if (response.success) {
					const marketList: Array<MarketItem> = response.data.marketList.map((market: any) => ({
						name: market.Name,
						entityID: market.ID,
						storeID: market.StoreID
					}));
					setList(marketList);
					setIsLoading(false);
				}
			});
		}
		else {
			setList([]);
		}
	}, [isOpen]);

	const handleCheckboxChange = (item: MarketItem, checked: boolean) => {
		if (checked && !selectedItems.some(i => i.entityID === item.entityID)) {
			setSelectedItems([...selectedItems, item]);
		}
		else {
			setSelectedItems(selectedItems.filter(i => i.entityID !== item.entityID));
		}
	}

	const onClick = () => {
		const api = new Api('queue', 'cfm');
		const items = selectedItems.map((item) => (item.entityID));
		if (items.length === 0) {
			Notify.Warn("Você precisa especificar uma loja para continuar.");
			return;
		}
		if (!state.templateName) {
			Notify.Warn("Nenhum nome para a campanha foi especificado.");
			return;
		}

		const productsDescriptions = products.map((product) => (product.description.trim()));

		const uri = getStageImageUri(3);
		const payload = {
			Name: state.templateName,
			Orientation: editor.orientation,
			Uri: uri,
			Products: JSON.stringify(productsDescriptions)
		};
		const request = api.request(member, {
			payload: payload,
			marketIDs: items
		});
		setIsLoading(true);
		api.post(request).then((response) => {
			if (response.success) {
				Notify.Success("Cartaz enviado à fila de impressão das lojas selecionadas.");
				closeModal();
			}
			else Notify.Error("Algo inesperado aconteceu!");
		}).finally(() => setIsLoading(false));
	}

	const SaveButton: React.FC = React.memo(() => {
		return (
			<div onClick={onClick} className="min-w-fit px-4 bg-orange-300 hover:bg-orange-400 hover:border-blue-500 border-2 text-center text-gray-700
			rounded-lg cursor-pointer py-2 duration-300">
				Enviar às lojas Selecionadas
			</div>
		)
	});

	const BackButton: React.FC = React.memo(() => {
		return (
			<div onClick={closeModal} className="min-w-fit px-4 bg-gray-300 hover:bg-gray-400 hover:border-blue-500 border-2 text-center text-gray-700 hover:text-black
			rounded-lg cursor-pointer py-2 duration-300">
				Cancelar
			</div>
		)
	});

	const MarketSelector: React.FC = React.memo(() => {
		return (
			<div className="flex flex-col max-w-[60vw]">
				<div className="grid grid-cols-3 gap-3 py-3 overflow-auto">
					{
						list.map((item, index) => (
							<div key={item.entityID + '_' + index} className="whitespace-nowrap">
								<div className="bg-slate-200 py-2 px-4 rounded-md">
									<label className="cursor-pointer">
										<input type="checkbox" className="mr-2" checked={selectedItems.some(i => i.entityID === item.entityID)} onChange={e => handleCheckboxChange(item, e.target.checked)} />
										{item.name}
									</label>
								</div>
							</div>
						))
					}
				</div>
				<div className="flex flex-row justify-center w-full mt-3 space-x-2">
					<div className="w-fit">
						<BackButton />
					</div>
					<div className="w-fit">
						<SaveButton />
					</div>
				</div>
			</div>
		)
	});

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal} >
			<div className="flex flex-col justify-center items-center text-center max-h-[50em] min-w-[25vw]">
				<div className="flex flex-col mb-4">
					<h1 className="text-2xl mb-4">
						Enviar para Lojas
					</h1>
					<p>Selecione as lojas para onde deseja enviar este cartaz.</p>
					<p style={{ fontSize: 12 }} >Os cartazes enviados ficam pendentes na fila de impressão das lojas selecionadas.</p>
				</div>
				<div className="flex flex-row justify-around w-full">
					{
						isLoading
							? <EpjLoadingIcon />
							: <MarketSelector />
					}
				</div>
			</div>
		</HandleModal>
	)
}

export default MarketingSelectorModal;
