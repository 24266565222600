import { useProducts } from '@/contexts/products/ProductsContext';
import { FieldOption, ProductListItem, __FIELD_OPTIONS__ } from '@/types/products';
import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';

interface ProductFieldInterface {
	onChange: (value: keyof ProductListItem | null) => void,
	defaultValue?: keyof ProductListItem | null,
	isClearable?: boolean,
}

const ProductField: React.FC<ProductFieldInterface> = ({ onChange, defaultValue = null, isClearable = true }) => {
	const [selectedField, setSelectedField] = useState<keyof ProductListItem | null>(defaultValue);
	const { getConvertedCustomFieldsToProductFields } = useProducts();

	const options = useMemo(() => {
		return getConvertedCustomFieldsToProductFields();
	}, [getConvertedCustomFieldsToProductFields]);

	const _onChange = (value: keyof ProductListItem | null) => {
		setSelectedField(value);
		onChange(value);
	}

	useEffect(() => {
		setSelectedField(defaultValue);
	}, [defaultValue]);

	// Adiciona a opção "Selecione um campo" como uma das opções válidas
	const fallbackOption: FieldOption = { value: "selecione_um_campo" as keyof ProductListItem, label: 'Selecione um campo' };

	// Combina as opções convertidas com a opção de fallback
	const combinedOptions = useMemo(() => [fallbackOption, ...options], [options]);

	// Função para obter a opção ou 'Selecione um campo'
	const getSelectedOption = (value: keyof ProductListItem | null) => {
		const foundOption = combinedOptions.find((option) => option.value === value);
		return foundOption || fallbackOption;
	};

	return (
		<Select<FieldOption>
			isClearable={isClearable}
			menuPlacement="auto"
			noOptionsMessage={() => 'Campo'}
			options={combinedOptions}
			placeholder="Escolha um campo"
			onChange={(option) => _onChange(option?.value ?? null)}
			defaultValue={getSelectedOption(defaultValue)}
			value={getSelectedOption(selectedField)}
			isSearchable={false}
			styles={{
				control: (provided, state) => ({
					...provided,
					borderColor: state.isFocused ? '#ddd' : '#ccc',
					boxShadow: 'none', // Remove focus shadow
					'&:hover': {
						borderColor: '#ccc'
					}
				}),
				clearIndicator: (provided, state) => ({
					...provided,
					display: "none"
				}),
				indicatorsContainer: (provided, state) => ({}),
			}}
		/>
	);
};

export default ProductField;
