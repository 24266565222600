import { useSaveTemplate } from "@/contexts/modals/saveTemplate/SaveTemplateContext";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";

const Accordion = () => {
    const { marketList } = useSaveTemplate();

    return (
        <section className="w-full h-full overflow-x-hidden overflow-y-auto">
            <div className="">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4 ">
                        {
                            marketList.map((el) => (
                                <AccordionItem key={el.company.id} header={el.company.name} markets={el.markets} />
                            ))
                        }          
                    </div>
                </div>
            </div>
        </section>
    ) 
};

export default Accordion;

interface IAccordion {
    header: string,
    markets: {
        id: number,
        name: string,
        StoreID: number
    }[];
}

const AccordionItem: React.FC<IAccordion> = ({ header, markets }) => {
    const [checkedItems, setCheckedItems] = useState<boolean[]>(Array(markets.length).fill(false));
    const [active, setActive] = useState(false);
    const { selectedConstraint, setSelectedConstraint } = useSaveTemplate();
    
    useEffect(() => {
        if (selectedConstraint !== undefined) {
            // Marcando os checkboxes com base nos IDs fornecidos em selectedConstraint
            const initialCheckedState = markets.map((market) => selectedConstraint.includes(market.id));
            setCheckedItems(initialCheckedState);
        }
    }, [selectedConstraint, markets]);
  
    const handleToggle = () => {
      setActive(!active);
    };
  
    const handleChangeAllSelected = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedValue = event.target.checked;
        setCheckedItems(Array(markets.length).fill(newCheckedValue));
    
        if (newCheckedValue) {
            const allMarketIDs = markets.map((market) => market.id);
            setSelectedConstraint(Array.from(new Set([...selectedConstraint, ...allMarketIDs])));
        } else {
            const selectedMarketIDs = markets.filter((market, index) => checkedItems[index]).map((market) => market.id);
            setSelectedConstraint(selectedConstraint.filter((item) => !selectedMarketIDs.includes(item)));
        }
    };

    const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = event.target.checked;
        setCheckedItems(newCheckedItems);
        
        const selectedMarket = markets[index].id;
        if (event.target.checked) {
            selectedConstraint === undefined ? [] : selectedConstraint;
            setSelectedConstraint([...selectedConstraint, selectedMarket]);
        } else {
            setSelectedConstraint(selectedConstraint.filter((item) => item !== selectedMarket));
        }
    };
    
    return (
        <div className="w-full border-b border-zinc-200">
            <div className="flex flex-row justify-between">
                <button
                    className={`faq-btn flex w-full text-left`}
                    onClick={() => handleToggle()}
                >
                    <div className="mr-5 flex h-[100%] w-full max-w-[40px] items-center justify-center text-primary">
                        <svg
                            className={`fill-primary fill-zinc-400 stroke-primary duration-200 ease-in-out ${
                            active ? "rotate-180" : ""
                            }`}
                            width="15"
                            height="8"
                            viewBox="0 0 17 10"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                            fill=""
                            stroke=""
                            />
                        </svg>
                    </div>

                    <div className="w-full">
                        <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                            {header}
                        </h4>
                    </div>
                </button>
                <FormControlLabel
                    label=""
                    control={
                        <Checkbox
                        checked={checkedItems.every(Boolean)}
                        indeterminate={!checkedItems.every(Boolean) && checkedItems.some(Boolean)}
                        onChange={handleChangeAllSelected()}
                        />
                    }
                />   
            </div>
            <div id="list" className={`flex flex-col flex-align-center justify-start pl-[62px] overflow-hidden ${active ? "h-full" : "h-0"}`}>
                {
                    markets.map((label, index) => (
                        <FormControlLabel
                            key={label.id}
                            label={label.name}
                            control={<Checkbox checked={checkedItems[index]} onChange={handleChange(index)} />}
                        />
                    ))
                }
            </div>
        </div>
    );
  };
  
