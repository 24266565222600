import React, { useEffect, useRef, useState } from 'react';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { SetterName } from '@/contexts/editor/DynamicPromoContext';
import { DynamicTextConfigButton } from './partials/TextHeader';
import PromoTextAssociationSelect from './partials/PromoTextAssociationSelect';
import { __FIELD_OPTIONS__ } from "@/components/editor/sidebar/partials/PromoTextAssociationSelect";
import MuiSelect from '@/components/buttons/MuiSelect';
import { Orientation } from '@/types/canvas';
import DangerConfirmationModal from '@/components/modals/warnings/DangerConfirmationModal';
import BooleanInputRow from '@/components/inputs/rows/BooleanInputRow';

type EditorPreferencesContainerProps = {};

type OrientationTypeProp = { label: string, value: NonNullable<Orientation> };
const orientationTypes: Array<OrientationTypeProp> = [
	{ label: 'Vertical', value: 'vertical' },
	{ label: 'Horizontal', value: 'horizontal' },
	{ label: 'Story', value: 'story' },
	{ label: 'Post', value: 'post' },
	{ label: 'P21', value: 'p21' },
	{ label: 'P30', value: 'p30' },
];

const EditorPreferencesContainer = ({ }: EditorPreferencesContainerProps) => {
	const { state, getElementDataById, isFuturePrice, setFuturePrice } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const { current, editor, templateId, templateName } = state;
	const { orientation } = editor;

	const [selectedOrientationType, setSelectedOrientationType] = useState<Orientation>(orientation);

	const prevOrientationRef = useRef<Orientation>(orientation);
	const prevSelectedOrientationTypeRef = useRef<Orientation>(selectedOrientationType);

	useEffect(() => {
		if (prevOrientationRef.current !== orientation) {
			setSelectedOrientationType(orientation);
		}
		prevOrientationRef.current = orientation;
	}, [orientation]);

	useEffect(() => {
		if (prevSelectedOrientationTypeRef.current !== selectedOrientationType) {
			stateDispatch({ type: "updateOrientation", orientation: selectedOrientationType });
		}
		prevSelectedOrientationTypeRef.current = selectedOrientationType;
	}, [selectedOrientationType]);

	const element = getElementDataById(current.id);

	const enabled = Boolean(element && element.attributes.id !== "background");

	const DeleteModalContent = () => {
		return (<>Deseja realmente <b>apagar</b> o layout atual?<br />Esta ação não pode ser desfeita.</>);
	}

	const onChangePromoAssociation = (setter: SetterName | undefined) => {
		if (setter) {
			const option = __FIELD_OPTIONS__.find(item => item.value === setter);
			if (current.id && option && element) {
				stateDispatch({
					type: "updateElement",
					payload: {
						id: current.id,
						attributes: {
							...element.attributes,
							associated: option.value
						}
					}
				});
			}
		}
		else {
			if (current.id && element) {
				stateDispatch({
					type: "updateElement",
					payload: {
						id: current.id,
						attributes: {
							...element.attributes,
							associated: null
						}
					}
				});
			}
		}
	}

	const [eraseModalVisible, setEraseModalVisibility] = useState(false);

	const onResetCanvas = () => {
		stateDispatch({ type: "resetCanvas", newValues: { templateId, templateName } });
	}

	const handleUseBackgroundRemover = (value: boolean) =>
		stateDispatch({ type: "useBackgroundRemover", value: value });

	const handleFuturePrice = () => setFuturePrice(!isFuturePrice);

	return (
		<>
			<ul className="flex-1 inline-flex list-none min-w-full">
				<div className='flex flex-col w-full '>
					<Header />
					<div className="px-4 xl:px-10">
						<DangerConfirmationModal isOpen={eraseModalVisible}
							onClose={() => setEraseModalVisibility(false)} onConfirm={onResetCanvas}
							message={<DeleteModalContent />} />

						<BooleanInputRow label="Remover Plano de Fundo" description={'Sempre remover plano de fundo de imagens (pode consumir mais tempo). Será necessário recarregar elementos existentes para que as mudanças tenham efeito.'}
							defaultValue={state.editor.useBackgroundRemover} onChange={value => handleUseBackgroundRemover(value)}
						/>

						<BooleanInputRow label="Preço Futuro" description={''}
							defaultValue={isFuturePrice} onChange={handleFuturePrice} />

						<div className='flex w-full items-center pt-4'>
							<label className='w-1/3'>Associar com:</label>
							<div className="w-2/3">
								<PromoTextAssociationSelect
									defaultValue={element?.attributes.associated || null}
									onChange={onChangePromoAssociation}
									disabled={!enabled}
								/>
							</div>
						</div>
						{
							templateId !== null && (
								<React.Fragment>
									<div className='flex items-center pt-4'>
										<label className='w-1/3'>Template:</label>
										<div className="flex ml-1 w-2/3 gap-2 items-">
											<React.Fragment>
												<MuiSelect title="Papel" options={orientationTypes} selectedValue={selectedOrientationType} setSelectedValue={setSelectedOrientationType} />
											</React.Fragment>
											<button className='flex items-center py-3 px-2.5 bg-rose-500 text-white rounded-md' onClick={() => setEraseModalVisibility(true)}>
												<svg className='w-6 fill-white' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fillRule="nonzero" /></svg>
											</button>
										</div>
									</div>
								</React.Fragment>
							)
						}
					</div>
				</div>
			</ul>
		</>
	);
}

export default EditorPreferencesContainer;

const Header: React.FC = React.memo(() => (
	<div className="px-4 xl:px-10 inline-flex list-none w-full border-b-2">
		<div className="flex flex-col w-full">
			<div className="flex flex-row justify-between items-center w-full py-2">
				<span className="font-bold text-2xl">Opções</span>
				<DynamicTextConfigButton />
			</div>
		</div>
	</div>
));
