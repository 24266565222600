import { useCanvas } from "@/contexts/editor/CanvasContext";

interface ILayoutHeader {
}

const LayoutHeader: React.FC<ILayoutHeader> = ({ }) => {
	return (
		<div className="flex-1 inline-flex list-none py-4 w-full">
			<div className="flex flex-col w-full">
				<div className="flex flex-row justify-between items-center w-full border-b-2 pb-2 px-4 xl:px-10">
					<span className="font-bold text-2xl">Modelos</span>
				</div>
			</div>
		</div>
	)
};

export default LayoutHeader;
