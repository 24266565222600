import React, { Fragment, useRef, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { freeSet } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import Notify from '@/helpers/Notify';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { usePopoverAutoClose } from '@/hooks/usePopoverAutoClose';
import TrashIcon from '../svg/TrashIcon';

interface PopoverProps {
	productID: string,
	displayPosition: number
}

interface ProductPanelInterface {
	productID: string,
	popoverRef: React.RefObject<HTMLButtonElement | null>,
	displayPosition: number
}

const DeleteProductPopover: React.FC<PopoverProps> = ({ productID, displayPosition }) => {
	const { popoverRef, hiddenInputRef } = usePopoverAutoClose();

	return (
		<Popover>
			{({ open }: { open: boolean }) => (
				<React.Fragment>
					<Popover.Button ref={popoverRef}
						className="h-full flex mr-3 text-white items-center hover:bg-blue-400 px-3 py-1.5 cursor-pointer rounded-lg"
						onClick={() => null}
					>
						<TrashIcon className='w-5 stroke-[1.7]' />
					</Popover.Button>

					{/* Armazena o estado do 'open' uma vez que não temos acesso a ele no componente. */}
					<input ref={hiddenInputRef} type="hidden" value={open ? 'true' : 'false'} />

					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel className={`absolute rounded-lg bg-white dark:bg-slate-500 dark:text-white left-100 z-10 mt-2 min-w-fit -translate-x-56 transform px-0 lg:max-w-3xl`}>
							<div className={`overflow-hidden rounded-lg shadow-sm ring-1 ring-black ring-opacity-5 w-fit`}>
								<div className="h-fit w-[300px] flex flex-col justify-center items-center" >
									<ProductPanel productID={productID} popoverRef={popoverRef} displayPosition={displayPosition} />
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</React.Fragment>
			)}
		</Popover>
	)
}

export default DeleteProductPopover;

const ProductPanel: React.FC<ProductPanelInterface> = ({ productID, popoverRef, displayPosition }) => {
	const { getElementsByProductId, state } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { elements, products } = state;

	const handleDeleteProduct = (operation: 'remove' | 'update') => {
		const pElements = getElementsByProductId(productID);
		if (pElements.length > 0) {
			pElements.forEach(element => {
				if (operation === 'remove') stateDispatch({ type: "removeElement", id: element.attributes.id });
				else if ('update') return stateDispatch({ type: "attachElementToProduct", id: element.attributes.id, productId: null });
			});
			if (operation === 'remove' && products.ids.length > 1 && products.ids.length !== displayPosition)
				Notify.warn(`Novos elementos passaram a ser o 'Produto ${displayPosition}'.`)
					.left();
		}
		stateDispatch({ type: "removeProduct", id: productID });
		// Fecha o popover após o click.
		if (popoverRef && popoverRef.current) {
			popoverRef.current.click();
		}
	}

	const commonClasses = "transition-all font-semibold bg-rose-500 duration-200 text-center text-white p-1 rounded-lg cursor-pointer w-full"

	return (
		<div className='flex flex-col w-full p-4' >
			<span className="mb-2" >O que deseja fazer com os elementos associados após a remoção?</span>
			<div className='flex flex-row w-full' >
				<button className={`${commonClasses} hover:bg-rose-600`} onClick={() => handleDeleteProduct('remove')} >Remover</button>
				<button className={`${commonClasses} ml-2 bg-amber-400 text-white`} onClick={() => handleDeleteProduct('update')} >Desassociar</button>
			</div>
		</div>
	)
}
