import ImageRowPreview from '@/components/queue/table/ImageRowPreview';
import { useCanvasProducts } from '@/contexts/editor/CanvasProductsContext';
import { useSearch } from '@/contexts/products/SearchContext';
import { ParentInterface } from '@/types';
import { ProductListItem } from '@/types/products';
import React from 'react';

const ResultTable: React.FC = () => {
	const { searchResults } = useSearch();
	const { addProduct, products, removeBy } = useCanvasProducts();

	const isProductInCanvas = (product: ProductListItem) => {
		return products.some(p => p.id === product.id);
	};

	return (
		<div className="w-full flex flex-col justify-center">
			<table className="min-w-full divide-y border-t divide-zinc-200">
				<thead className="bg-zinc-100">
					<tr>
						<Th>EAN</Th>
						<Th>Código Interno</Th>
						<Th>Descrição</Th>
						<Th>Preço</Th>
						<Th>Preço Oferta</Th>
						<Th>Preço Clube</Th>
						<Th>Preço Extra</Th>
						<Th>Data Inicio</Th>
						<Th>Data Termino</Th>
						<Th>Opções</Th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-zinc-200 ">
					{
						searchResults.map((p, i) => (
							<tr className='hover:bg-zinc-100' key={`${p.id}_${i}`}>
								<Td>{p.ean}</Td>
								<Td>{p.innerCode}</Td>
								<Td>{p.description}</Td>
								<Td>{p.sPrice}</Td>
								<Td>{p.oPrice}</Td>
								<Td>{p.cPrice}</Td>
								<Td>{p.ePrice}</Td>
								<Td>{p.initDate ? p.initDate.toString() : ""}</Td>
								<Td>{p.endDate ? p.endDate.toString() : ""}</Td>
								<Td>
									{
										isProductInCanvas(p) ? (
											<button onClick={() => removeBy('id', p.id)} className="px-3 py-2 w-24 text-white bg-rose-500 hover:bg-rose-600 rounded-md">
												Remover
											</button>
										) : (
											<button onClick={() => addProduct(p)} className="px-3 py-2 w-24 text-white bg-blue-500 hover:bg-blue-600 rounded-md">
												Selecionar
											</button>
										)
									}
								</Td>
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}

export default ResultTable;

const ImageIndicator = ({ data }: { data: any }) => {
	return (
		<div className='flex w-full justify-center'>
			<div
				style={{
					width: "20px",
					height: "20px",
					borderRadius: "50%",
					backgroundColor: data ? "green" : "gray"
				}}
			/>
		</div>
	);
};

const Th: React.FC<ParentInterface> = ({ children }) => {
	return (
		<th scope="col" className="px-4 py-3 text-left text-xs font-medium text-zinc-500 uppercase">
			{children}
		</th>
	)
}

const Td: React.FC<ParentInterface> = ({ children }) => {
	return (
		<td className="pl-4 py-4 whitespace-nowrap text-sm text-zinc-500">
			{children}
		</td>
	)
}
