import Dropzone from "@/components/cards/common/Dropzone";
import ImageHelper from "@/helpers/ImageHelper";
import { IMember } from "@/types/member";
import React, { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ImageType } from "@/types/canvas";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import LoadingButton from '@mui/lab/LoadingButton';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Stack from '@mui/material/Stack';


type RadioSectionProps = {
	current: ImageType | 'logo';
	onChange: (value: ImageType) => void;
}

type SaveButtonProps = {
	type: ImageType | "logo";
	isLoading: boolean;
	setLoading: (value: boolean) => void;
}

const ImageOptions: React.FC<{ member: IMember, updateImage: (src: string | null) => void, applyLogo: (src: string, index: number) => void }> = (
	{
		member,
		updateImage,
	}
) => {
	const { t: c } = useTranslation('canvas.translation');
	const [lastLogoState, setLastLogoState] = useState<ImageType>(null);
	const [saveButtonIsLoading, setSaveButtonIsLoading] = useState<boolean>(false);
	const {
		isEditing,
		getElementDataById,
		shapeInfo,
		state
	} = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const { current } = state;
	const data = getElementDataById(current.id);

	const [imageType, setImageType] = React.useState<ImageType | "logo">(data?.attributes.imageType || 'static');

	const handleChange = () => {
		if (saveButtonIsLoading || !imageType || imageType === 'logo') return;
		setSaveButtonIsLoading(true);

		if (current.id && data && data.attributes && data.ref && shapeInfo.isImage) {
			const attributes = data.attributes;
			stateDispatch({
				type: "updateElement",
				payload: {
					id: current.id,
					attributes: {
						...attributes,
						imageType: imageType
					}
				}
			});
		}
		setTimeout(() => {
			setSaveButtonIsLoading(false);
		}, 1000);
	}

	useEffect(() => {
		if (imageType?.includes("logo") && lastLogoState) setImageType(lastLogoState);
		else if (imageType === 'logo' && !lastLogoState) setImageType('logo_1');
	}, [lastLogoState, imageType]);

	useEffect(() => {
		handleChange();
	}, [imageType]);

	return (
		<div>
			<div className="flex flex-row px-2">
				<h2 className="w-full text-2xl" >{c("editor.images.label")}:</h2>
				<SaveButton type={imageType} isLoading={saveButtonIsLoading} setLoading={(value) => setSaveButtonIsLoading(value)} />
			</div>

			{
				isEditing && (
					<div className="mt-2 p-2 no-text-highlight" >
						<div className="">
							<RadioSection current={imageType} onChange={(value) => setImageType(value)} />
						</div>
						{
							is_logo(imageType) && (
								<div className="">
									<LogoRadioSection current={imageType} onChange={(value) => setLastLogoState(value)} />
								</div>
							)
						}
					</div>
				)
			}

			{
				member.can('upload_images') && isEditing && imageType === 'static' && (
					<div className="mt-2 p-2" >
						<h3 className="w-full text-center text-xl mb-2">Fazer Upload</h3>
						<Dropzone onChange={(src: string | null) => updateImage(src)} />
					</div>
				)
			}

		</div>
	)
}

export default ImageOptions;

const RadioSection: React.FC<RadioSectionProps> = ({ onChange, current }) => {

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange((event.target as HTMLInputElement).value as ImageType);
	};

	return (
		<FormControl>
			<FormLabel id="demo-radio-buttons-group-label">Tipo de Imagem</FormLabel>
			<RadioGroup
				row
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue="female"
				name="radio-buttons-group"
				onChange={handleChange}
			>
				<FormControlLabel checked={current === 'static'} value="static" control={<Radio />} label="Estática" />
				<FormControlLabel checked={current === 'header'} value="header" control={<Radio />} label="Testeira" />
				<FormControlLabel checked={current === 'footer'} value="footer" control={<Radio />} label="Rodapé" />
				<FormControlLabel checked={current === 'background'} value="background" control={<Radio />} label="Plano de Fundo" />
				<FormControlLabel checked={current?.includes("logo")} value="logo" control={<Radio />} label="Logo" />
			</RadioGroup>
		</FormControl>
	);
}

const LogoRadioSection: React.FC<RadioSectionProps> = ({ onChange, current }) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value as ImageType;
		onChange(value);
	};

	return (
		<FormControl>
			<FormLabel id="demo-radio-buttons-group-label">Variante</FormLabel>
			<RadioGroup
				row
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue="female"
				name="radio-buttons-group"
				onChange={handleChange}
			>
				<FormControlLabel checked={current === 'logo_1'} value="logo_1" control={<Radio />} label="Logo 1" />
				<FormControlLabel checked={current === 'logo_2'} value="logo_2" control={<Radio />} label="Logo 2" />
				<FormControlLabel checked={current === 'logo_3'} value="logo_3" control={<Radio />} label="Logo 3" />
			</RadioGroup>
		</FormControl>
	);
}

const SaveButton: React.FC<SaveButtonProps> = ({ type, isLoading, setLoading }) => {
	const {
		getElementDataById,
		shapeInfo,
		state
	} = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const { current } = state;
	const data = getElementDataById(current.id);

	const handleClick = () => {
		if (isLoading || !type || type === 'logo') return;
		setLoading(true);

		if (current.id && data && data.attributes && data.ref && shapeInfo.isImage) {
			const attributes = data.attributes;
			stateDispatch({
				type: "updateElement",
				payload: {
					id: current.id,
					attributes: {
						...attributes,
						imageType: type
					}
				}
			});
		}
		setTimeout(() => {
			setLoading(false);
		}, 5000);
	}

	return (
		<Stack direction="row" spacing={2}>
			<LoadingButton loading={isLoading} variant="contained" endIcon={<DoneAllOutlinedIcon />}
				onClick={handleClick}
			>
				Salvar
			</LoadingButton>
		</Stack>
	);
}

function is_logo(value: ImageType | 'logo') { return value && value.includes('logo') }
