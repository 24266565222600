import React from 'react';
import { useTranslation } from 'next-i18next';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { freeSet } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { ElementType } from '@/types/canvas';
import ComponentProvider from '@/components/editor/ComponentProvider';
import { now } from '@/helpers';

type FormsContainerProps = {};

const FormsContainer = ({ }: FormsContainerProps) => {
    const { t: c } = useTranslation('canvas.translation');

    const { stateDispatch } = useCanvasDispatch();

    const handleAddElement = (type: ElementType) => {
        const newElement = createElement(type);

        stateDispatch({ type: "addElement", attributes: newElement });
        stateDispatch({ type: "selectShape", payload: { id: newElement.id, type: newElement.type } })
        switch (type) {
            case 'text':
                stateDispatch({ type: "updateSection", section: 'texts' });
                break;
            case 'image':
                stateDispatch({ type: "updateSection", section: 'images' });
                break;
        }
    };

    return (
        <ul className="flex-1 inline-flex list-none mx-3">

            <div className="flex justify-center items-center">
                <IconButton title={c("editor.shapes.rectangle")} icon={freeSet.cilSquare} onClick={() => handleAddElement('rectangle')} />
                <IconButton title={c("editor.shapes.circle")} icon={freeSet.cilCircle} onClick={() => handleAddElement('circle')} />
                <IconButton title={c("editor.shapes.image")} icon={freeSet.cilImage} onClick={() => handleAddElement('image')} />
                <IconButton title={c("editor.shapes.text")} icon={freeSet.cilText} onClick={() => handleAddElement('text')} />

            </div>
        </ul>
    );
}

export default FormsContainer;

function IconButton({ icon, title, onClick }: { icon: string | string[], title: string, onClick: () => void }) {
    return (
        <React.Fragment>
            <span
                className="flex items-center hover:bg-orange-400 hover:text-white dark:text-white duration-500 rounded p-2 cursor-pointer"
                onClick={onClick} >
                <CIcon icon={icon} width={20} size="xxl" />
            </span>
        </React.Fragment>
    )
}

function createElement(type: ElementType) {
    switch (type) {
        case 'rectangle':
            return ComponentProvider.rectangle();
        case 'circle':
            return ComponentProvider.circle();
        case "image":
            return ComponentProvider.image({ image: `/images/no-img.png?t=${now()}`, imageType: "static" });
        case 'text':
            return ComponentProvider.text({ text: 'Novo Texto' });
        default:
            throw new Error(`Type '${type}' is not supported`);
    }
}
