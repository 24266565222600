import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { usePopoverAutoClose } from '@/hooks/usePopoverAutoClose';
import { useMember } from '@/contexts/member/MemberContext';
import MarketingSelectorModal from '../modals/editor/MarketingSelectorModal';

type SaveButtonProps = {
	setSaveModalVisibility: (value: boolean) => void;
}

export default function SaveButtonPopover({ setSaveModalVisibility }: SaveButtonProps) {
	const { member } = useMember();
	const { popoverRef, hiddenInputRef } = usePopoverAutoClose();

	const { isEditing } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const [showMarketingQueueModal, setShowMarketingQueueModal] = useState<boolean>(false);

	const handleSaveModel = () => {
		stateDispatch({ type: "unselect" });
		setSaveModalVisibility(true);
	}

	return (
		<Popover className="relative">
			{({ open, close }) => (
				<>
					<Popover.Button ref={popoverRef}
						className="h-full flex items-center cursor-pointer bg-orange-500 shadow-md shadow-orange-500/50 rounded-lg p-2"
						onClick={() => null}
					>
						<span className='mx-2 flex flex-row text-white'>
							<span className="font-semibold">
								Opções
							</span>
							<ChevronDownIcon
								className={`${open ? '' : 'text-opacity-70'}
                  ml-2 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80`}
								aria-hidden="true"
							/>
						</span>
					</Popover.Button>

					{/* Armazena o estado do 'open' uma vez que não temos acesso a ele no componente. */}
					<input ref={hiddenInputRef} type="hidden" value={open ? 'true' : 'false'} />

					<MarketingSelectorModal isOpen={showMarketingQueueModal} onClose={() => setShowMarketingQueueModal(false)} />

					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel className={`absolute left-1/2 z-10 mt-5 max-w-fit min-w-[350px] -translate-x-72 transform px-0 lg:max-w-3xl`}>
							<div className={`overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-fit`}>
								{/* { member.admin() && isEditing && <MenuOption label="Salvar Localmente" onClick={saveOnLocalStorage} /> } */}
								{member.can('send_to_any_queue') && <MenuOption label="Enviar para Lojas" onClick={() => { setShowMarketingQueueModal(true), close() }} />}
								{/* { member.can('send_to_queue') && <MenuOption label="Adicionar à Fila" onClick={addToQueue} /> } */}
								{/* { member.admin() && <MenuOption label="Download PNG" onClick={() => canvasToImage(stageRef)} /> } */}
								{member.can('create_banner') && isEditing && <MenuOption label="Salvar Modelo" onClick={() => { handleSaveModel(), close() }} />}
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}

type MenuOptionProps = {
	label: string;
	onClick: () => void;
}

function MenuOption({ label, onClick }: MenuOptionProps) {
	return (
		<div className={`relative gap-8 bg-white hover:bg-slate-100 cursor-default p-7 min-w-full justify-center items-center flex duration-500`}>
			<h2 className='text-gray-500 hover:text-black text-center w-full' >{label}</h2>
			<div onClick={onClick} className="min-w-fit px-4 bg-orange-400 hover:bg-orange-300 hover:border-orange-300 border-2 text-center text-white 
			rounded-lg cursor-pointer py-2 duration-300">
				Selecionar
			</div>
		</div>
	)
}
