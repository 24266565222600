import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

type OptionType<T> = { label: string; value: T };

type MuiSelectProps<T> = {
	selectedValue: T;
	setSelectedValue: (value: T) => void;
	options: Array<OptionType<T>>;
	title?: string;
};

function getStyles<T>(value: T, selectedValue: T, theme: Theme) {
	return {
		fontWeight: value === selectedValue ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
	};
}

const MuiSelect = <T,>({ selectedValue, setSelectedValue, options, title = 'Tipo' }: MuiSelectProps<T>) => {
	const theme = useTheme();

	const handleChange = (event: SelectChangeEvent<string>) => {
		event.stopPropagation();
		const { value } = event.target;
		const selectedOption = options.find(opt => String(opt.value) === value);
		if (selectedOption) setSelectedValue(selectedOption.value);
	};

	return (
		<div className='w-full'>
			<FormControl size="small" fullWidth={true}>
				<InputLabel id="mui-select-label">{title}</InputLabel>
				<Select
					labelId="mui-select-label"
					id="mui-select"
					value={String(selectedValue) ?? ''}
					onChange={handleChange}
					input={<OutlinedInput id="mui-select-chip" label="Chip" />}
					renderValue={(selected) => {
						const item = options.find((opt) => opt.value === selected);
						return <Chip key={selected as any} label={item ? item.label : ''} />;
					}}
					MenuProps={MenuProps}
				>
					{
						options.map(({ label, value }) => (
							<MenuItem
								key={String(value)}
								value={String(value)}
								style={getStyles(value, selectedValue, theme)}
							>
								{label}
							</MenuItem>
						))
					}
				</Select>
			</FormControl>
		</div>
	);
};

export default MuiSelect;
