import React, { useRef, useEffect } from 'react';
import { isSpecialText, useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { Element, ElementData, DynamicTextTypes, ComboType } from '@/types/canvas';
import DropDownMenu from '@/components/buttons/DropDownMenu';

interface DynamicTextOptionsInterface {
	onChange: (value: DynamicTextTypes | null) => void,
	targetID: string | null,
	defaultValue?: DynamicTextTypes | null,
	isClearable?: boolean,
	disabled?: boolean,
	campaignComboType: ComboType[]
}

export type FieldOption = { value: DynamicTextTypes | "simple", label: string };
export type OptionGroup = { type: "group", name: string, id?: ComboType, items: Array<FieldOption> };

export const __FIELD_OPTIONS__: Array<FieldOption | OptionGroup> = [
	{
		type: 'group',
		name: 'Básico',
		items: [
			{ value: "simple", label: "Texto Comum" },
			{ value: 'text_club', label: "Texto Clube" },
			{ value: "market", label: "Loja" },
			{ value: "datetime", label: "Dia e Hora" },
			{ value: "date_and_market", label: "Data e Loja" },
		]
	},
	{
		type: 'group',
		name: 'Limites',
		items: [
			{ value: "cpf_limit", label: "Limite de CPF" },
			{ value: "date_limit", label: "Data Limite" },
			{ value: "expiration_date", label: "Data de Validade" }
		]
	},
	{
		type: 'group',
		id: 'price_a_cada',
		name: 'Preço A Cada',
		items: [
			{ value: "price_a_cada_full", label: "Preço A Cada (Completo)" },
			{ value: "price_a_cada_digits", label: "Preço A Cada (Dígitos)" },
			{ value: "price_a_cada_cents", label: "Preço A Cada (Centavos)" },
		]
	},
	{
		type: 'group',
		id: 'ap_x_un',
		name: 'A Partir de X UN',
		items: [
			{ value: "ap_x_UN__quantity", label: "Quantidade" },
			{ value: "ap_x_UN__price_digits", label: "APX Preço - Dígitos" },
			{ value: "ap_x_UN__price_cents", label: "APX Preço - Centavos" },
		]
	},
	{
		type: 'group',
		id: 'leve_x_por',
		name: 'Leve X Por',
		items: [
			{ value: "leve_Por__quantity", label: "Leve x Por" },
			{ value: "leve_Por__price_digits", label: "Leve Por Preço - Dígitos" },
			{ value: "leve_Por__price_cents", label: "Leve Por Preço - Centavos" },
		]
	},
	{
		type: 'group',
		id: 'oferta_pack',
		name: 'Oferta Pack',
		items: [
			{ value: "oferta_pack__quantity", label: "Quantidade Pack" },
			{ value: "oferta_pack__price_digits", label: "Oferta Pack - Dígitos" },
			{ value: "oferta_pack__price_cents", label: "Oferta Pack - Centavos" },
		]
	},
	{
		type: 'group',
		id: 'xOff',
		name: 'X% Off',
		items: [
			{ value: "xOff_value", label: "Porcentagem de Desconto" },
			{ value: "xUN_value", label: "Quantidade de Itens" },
			{ value: "xOff_price_digits", label: "xOff Preço - Dígitos" },
			{ value: "xOff_price_cents", label: "xOff Preço - Centavos" },
		]
	},
	{
		type: 'group',
		id: 'compre_e_ganhe',
		name: 'Compre e Ganhe',
		items: [
			{ value: "compre_e_ganhe__un", label: "Quantidade de Itens" },
			{ value: "compre_e_ganhe__item", label: "Brinde" },
		]
	},
	{
		type: 'group',
		id: 'leve_x_pague_y',
		name: 'Leve X Pague Y',
		items: [
			{ value: "leveX_pagueY__x", label: "Leve X" },
			{ value: "leveX_pagueY__y", label: "Pague Y" },
			{ value: "leveX_pagueY_digits", label: "Preço - Dígitos" },
			{ value: "leveX_pagueY_cents", label: "Preço - Centavos" },
		]
	},
	{
		type: 'group',
		id: 'mais_xx',
		name: '+XX,XX Leve',
		items: [
			{ value: "maisXX_value", label: "+XX (Preço)" },
			{ value: "maisXX_item", label: "+XX (Item)" },
		]
	},
	{
		type: 'group',
		id: 'cx_mx_leve',
		name: 'Compre X +XX,XX Leve',
		items: [
			{ value: "compreX_maisX_leve__x", label: "Compre X+X Leve (Quantidade)" },
			{ value: "compreX_maisX_leve__value", label: "Compre X+X Leve  (Valor)" },
			{ value: "compreX_maisX_leve__item", label: "Compre X+X Leve  (Item)" },
		]
	},
	{
		type: 'group',
		id: 'installments',
		name: 'Parcelado',
		items: [
			{ value: "installments__quantity", label: "Número de Parcelas" },
			{ value: "installments__price_digits", label: "Preço - Dígitos" },
			{ value: "installments__price_cents", label: "Preço - Centavos" },
		]
	},
];

const DynamicTextOptions: React.FC<DynamicTextOptionsInterface> = ({ onChange, targetID = null, defaultValue = null, campaignComboType, isClearable = true, disabled = true }) => {
	const { getElementDataById } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const _onChange = React.useCallback((option: { value: string, label: React.ReactNode }) => {
		const value = option.value as DynamicTextTypes | "simple" | null;
		onChange(value === "simple" ? null : value);

		const element = getElementDataById(targetID);
		if (element) {
			if (value && value !== "simple") {
				const newAttributes = updateTextByType(value, element);
				const isDigitPrice = isSpecialText(value) && value?.includes('digits');
				const isCentsPrice = isSpecialText(value) && value?.includes('cents');

				const digitAttributes: Partial<Element> = {
					width: 100,
					height: 70
				};
				const centsAttributes: Partial<Element> = {
					width: 55,
					height: 50
				};

				const priceAttributes: Partial<Element> = isDigitPrice ? digitAttributes
					: (isCentsPrice ? centsAttributes : { align: 'center' });

				stateDispatch({
					type: "updateElement",
					payload: {
						id: newAttributes.id, attributes: { ...newAttributes, ...priceAttributes }
					}
				});
			}
			else {
				const newAttributes = { ...element.attributes, text: "Novo Texto", defaultText: null, textType: null, align: 'center' };
				stateDispatch({
					type: "updateElement",
					payload: {
						id: newAttributes.id, attributes: newAttributes
					}
				});
			}
		}
	}, [onChange, targetID, getElementDataById]);

	const flattenedOptions: Array<FieldOption> = __FIELD_OPTIONS__.flatMap(opt => {
		if ('type' in opt && opt.type === 'group') {
			return opt.items;
		}
		return opt as FieldOption;
	});
	const defaultOption = flattenedOptions.find(opt => opt.value === defaultValue);

	// Ref para o contêiner do menu dropdown
	const menuRef = useRef<HTMLDivElement>(null);

	// Listener para cliques fora do menu
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				onChange(null); // Chamando onChange com null para resetar o valor
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuRef, onChange]);

	return (
		<div ref={menuRef} className='w-full z-50'>
			<DropDownMenu defaultOption={defaultOption} onChange={_onChange} disable={disabled} campaignComboType={campaignComboType} />
		</div>
	);
};

export default DynamicTextOptions;

export function updateTextByType(type: DynamicTextTypes, data: ElementData): Element {
	const element = data.attributes;
	if (element.textType !== type) {
		const text = getTextForType(type);
		if (!text) throw new Error("Invalid type: " + type);

		element.text = text;
		element.textType = type;
		element.defaultText = isSpecialText(type) ? text : element.text;
	}
	return element;
}

export function getTextForType(option: DynamicTextTypes | null): string | null {
	if (!option) return null;

	const typeMappings: Record<DynamicTextTypes, string> = {
		'text_club': "Ative e Pague",
		'datetime': "dd/MM/yyyy, HH:mm:ss",
		'market': "Loja [[x]]",
		'date_and_market': "dd/MM/yyyy - Loja [[x]]",
		'cpf_limit': "LIMITADO A [[x]] POR CPF",
		'date_limit': "OFERTA VÁLIDA DE [[x]]",
		'expiration_date': "DATA DE VALIDADE: [[x]]",
		'price_a_cada_digits': "00",
		'price_a_cada_cents': ",00",
		'price_a_cada_full': "0,00",
		'ap_x_UN__quantity': "A PARTIR DE [[x]] UNIDADE(S)",
		'ap_x_UN__price_digits': "00",
		'ap_x_UN__price_cents': ",00",
		'leve_Por__quantity': "LEVE [[x]] POR:",
		'leve_Por__price_digits': "00",
		'leve_Por__price_cents': ",00",
		'xOff_value': "[[x]]%",
		'xUN_value': "[[x]] UN",
		'xOff_price_digits': "00",
		'xOff_price_cents': ",00",
		'compre_e_ganhe__un': "NA COMPRA DE [[x]] UNIDADES",
		'compre_e_ganhe__item': "GANHE [[x]]",
		'leveX_pagueY__x': "LEVE [[x]]",
		'leveX_pagueY__y': "PAGUE [[x]]",
		'leveX_pagueY_digits': "00",
		'leveX_pagueY_cents': ",00",
		'maisXX_value': "+ [[x]] LEVE",
		'maisXX_item': "TEXTO LEVE",
		'compreX_maisX_leve__x': "NA COMPRA DE [[x]] UNIDADES",
		'compreX_maisX_leve__value': "+ [[x]] LEVE",
		'compreX_maisX_leve__item': "TEXTO LEVE",
		'installments__quantity': "EM [[x]] VEZES SEM JUROS",
		'installments__price_digits': "00",
		'installments__price_cents': ",00",
		'price_per_gram': "[[x]] kg",
		'oferta_pack__quantity': "O FARDO C/ [[x]] UNIDADES SAI POR:",
		'oferta_pack__price_digits': "00",
		'oferta_pack__price_cents': ",00",
	};
	return typeMappings[option] || null;
}
