import React from 'react';
import { Menu } from '@headlessui/react';
import { ArrowDown, ArrowUp } from '@/components/svg/Arrows';
import TextHelper from '@/helpers/TextHelper';

type Item = {
    label: string,
    data: any
}

type Option = {
    label: string,
    onClick: (item: any) => void
}

interface DropdownButtonInterface {
    title: string,
    items: Item[],
    onClickFooter: () => void,
    footerLabel?: string,
    optionButton?: Option,
    showBadge?: boolean
}

const DropdownButton: React.FC<DropdownButtonInterface> = ({ title, items, onClickFooter, footerLabel, optionButton, showBadge }) => {
    const onClickOption = (e: React.MouseEvent, item: any) => {
        e.stopPropagation();
        optionButton?.onClick(item);
    }

    return (
        <Menu as="div" className="relative inline-block text-left">

            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 shadow-md shadow-blue-500/50 rounded-md  hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <span className='flex'>
                    <span className="whitespace-nowrap">
                        {title}
                    </span>
                    {
                        showBadge &&
                        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full h-5 w-5 flex items-center justify-center text-white text-xs">
                            {items.length}
                        </span>
                    }
                    <ArrowDown className='ml-2 m-auto' />
                </span>
            </Menu.Button>

            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-[24rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-transform duration-200 transform scale-95 ease-in-out z-10">
                <div className="py-1 max-h-[50vh] overflow-auto" onClick={e => e.stopPropagation()}>
                    {
                        items.map((item, index) => (
                            <Menu.Item key={index}>
                                {({ active }) => (
                                    <div
                                        className={`${active ? 'bg-blue-500 text-white' : 'text-gray-700'
                                            } flex justify-between w-full px-4 py-2 text-sm cursor-pointer`}
                                    >
                                        <span>{TextHelper.charLimit(item.label, 40)}</span>
                                        {
                                            optionButton && (
                                                <span onClick={(event) => onClickOption(event, item.data)}>{optionButton.label}</span>
                                            )
                                        }
                                    </div>
                                )}
                            </Menu.Item>
                        ))
                    }
                </div>
                <div className="px-4 py-3 bg-blue-100 text-center rounded-b-md cursor-pointer" onClick={onClickFooter}>
                    <span onClick={onClickFooter} className="text-sm font-medium text-blue-600 hover:text-blue-500">
                        {footerLabel}
                    </span>
                </div>
            </Menu.Items>
        </Menu>
    )
}

DropdownButton.defaultProps = {
    footerLabel: "Clique aqui"
}

export default DropdownButton;
