import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useCanvas } from "@/contexts/editor/CanvasContext";
import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";
import { ModalInterface } from "@/types";
import { SaveTemplateProvider, useSaveTemplate } from "@/contexts/modals/saveTemplate/SaveTemplateContext";
import { RestrictVisibility } from "./saveTemplateOptions/RestrictVisibilityOptions";
import { SaveTemplateOptions } from "./saveTemplateOptions/SaveTemplateOption";
import EpjLoadingIcon from "@/components/animations/EpjLoadingIcon";

interface SaveTemplateModalInterface extends ModalInterface { }

const SaveTemplateModal: React.FC<SaveTemplateModalInterface> = ({ isOpen, onClose }) => {
	const { member } = useMember();
	const api = new Api('company');

	const {
		setPrivacyOption,
		setCompanySelected,
		setSelectedConstraint,
	} = useSaveTemplate();

	const {
		state,
		isLoadingTemplateData,
		setIsLoadingTemplateData
	} = useCanvas();

	const { templateId, templateName } = state;
	const [inputHasError, setInputHasError] = useState(Boolean(templateName));
	const [step, setStep] = useState(member.isAdmin ? 0 : 1);

	const closeModal = () => {
		onClose();
		setIsLoadingTemplateData(false);
		handleChangeActiveStep(0);
		setSelectedConstraint([]);
	}

	useEffect(() => {
		if (isOpen) {
			setInputHasError(!templateName);
		}
	}, [isOpen]);

	useEffect(() => {
		(async () => {
			if (templateId && isOpen) {
				setIsLoadingTemplateData(true);
				const api = new Api('templates', 'g');
				const request = api.request(member, { templateID: templateId });
				api.post(request).then((response) => {
					if (response.success && "template" in response.data) {
						const { template } = response.data;
						setPrivacyOption(template.Privacy || "private");
						setSelectedConstraint(template.Markets || []);
					}
				});
				await getCompanyList();
				setIsLoadingTemplateData(false);
			}
		})();
	}, [isOpen, templateId]);

	const getCompanyList = async () => {
		if (member.isLogged()) {
			const companies: string[] = [];
			const response = await api.post({ hash: member.hash }, 'gl');
			if (response.success) {
				const list = response.data.companyList;
				list.forEach((company: any) => {
					companies.push(company.Name);
				});
				setCompanySelected(companies);
			}
		}
	}

	const handleChangeActiveStep = (step: number) => {
		setStep(step);
	}

	const handleStepSelected = "fill-zinc-800 text-zinc-800 transition-all ease-in-ou duration-300 pl-2"// Estilos para quando estiver em um step selecionado.
	const handleStepStyles = `flex flex-row gap-4 fill-zinc-400 font-semibold items-center my-2 hover:text-zinc-500 hover:fill-zinc-500 transition-all ease-in-ou duration-300`

	const options = [
		<RestrictVisibility />,
		<SaveTemplateOptions isOpen={isOpen} onClose={onClose} />
	]

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal} closable={false}
			bgClass={isLoadingTemplateData ? "none" : "bg-white"}
			className={isLoadingTemplateData ? "shadow-none" : "shadow-md rounded-xl"} dontWantPadding={true}>
			{
				isLoadingTemplateData ? <EpjLoadingIcon />
					: (
						<div className="max-w-[60rem] min-w-[50rem] h-[35rem] flex flex-row text-center">
							<div className="flex flex-col items-start justify-start bg-zinc-100 w-[20rem] rounded-l-3xl px-8 py-6 border-r border-zinc-300">
								<span className="mb-5 text-lg text-zinc-600 font-semibold">Configurações</span>
								<ul className="text-start text-md font-semibold text-zinc-500">
									{
										(member.isAdmin || member.role === 'manager') && (
											<li className={`${handleStepStyles} ${step === 0 ? handleStepSelected : null}`} >
												<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
												<button onClick={() => handleChangeActiveStep(0)}>Visibilidade</button>
											</li>
										)
									}
									<li className={`${handleStepStyles} ${step === 1 ? handleStepSelected : null}`}>
										<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-4" height="18" width="14" viewBox="0 0 384 512"><path d="M0 48C0 21.5 21.5 0 48 0l0 48V441.4l130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4V48H48V0H336c26.5 0 48 21.5 48 48V488c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488V48z" /></svg>
										<button onClick={() => handleChangeActiveStep(1)}>Salvar</button>
									</li>
								</ul>
							</div>
							<div className="w-[80rem] p-10 flex items-start justify-center">
								{options[step]}
							</div>
						</div>
					)
			}
		</HandleModal>
	)
}


export default SaveTemplateModal;
