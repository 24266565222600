import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useMember } from '@/contexts/member/MemberContext';

interface RequirePermissionInterface {
  onGrantAccess?: (() => void) | undefined;
  onRejectAccess?: (() => void) | undefined;
}

const defaultActions: RequirePermissionInterface = {}

const useRequireLogin = ({ onGrantAccess, onRejectAccess }: RequirePermissionInterface = defaultActions) => {
	const { member, isFetchingData } = useMember();
	const router = useRouter();

	useEffect(() => {
		if (!isFetchingData)
		{
			if (member.isLogged())
			{
				onGrantAccess?.();
			}
			else if (!member.isLogged())
			{
				router.replace('/'); // Redireciona para a tela principal se o usuário não estiver logado
				onRejectAccess?.();
			}
		}
	}, [member, router, isFetchingData]);
};

export default useRequireLogin;
