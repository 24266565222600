import { useCompany } from "@/contexts/company/CompanyContext";
import { useSearch } from "@/contexts/products/SearchContext";
import SystemInfo from "@/helpers/SystemInfo";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useEffect } from "react";

type GlobalSearchType = "historic" | "integration" | "client_bridge";

const SearchGlobalType = () => {
    const { company } = useCompany();
    const { searchType, setSearchType: setGlobalSearchType } = useSearch();

    const handleGlobalSearchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setGlobalSearchType(event.target.value as GlobalSearchType);
    };

    useEffect(() => {
        if (searchType === "client_bridge" && !company?.clientAPIEnabled) {
            setGlobalSearchType("historic");
        }
    }, []);

    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={searchType}
                onChange={handleGlobalSearchTypeChange}>
                {
                    (SystemInfo.isIntranet || company?.clientAPIEnabled) && (
                        <div className="flex items-center mt-2 w-full">
                            {
                                company?.clientAPIEnabled ? (
                                    <FormControlLabel
                                        value="client_bridge"
                                        control={
                                            <Radio
                                                sx={{
                                                    color: orange[800],
                                                    '&.Mui-checked': {
                                                        color: orange[800],
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <span className="tracking-wide font-sans">
                                                Salestag API
                                            </span>
                                        }
                                    />
                                ) : (
                                    <FormControlLabel
                                        value="integration"
                                        control={
                                            <Radio
                                                sx={{
                                                    color: orange[800],
                                                    '&.Mui-checked': {
                                                        color: orange[800],
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <span className="tracking-wide font-sans">
                                                Integração
                                            </span>
                                        }
                                    />
                                )
                            }
                            <FormControlLabel
                                value="historic"
                                control={
                                    <Radio
                                        sx={{
                                            color: orange[800],
                                            '&.Mui-checked': {
                                                color: orange[800],
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 18,
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <span className="tracking-wide font-sans">
                                        CSV
                                    </span>
                                }
                            />
                        </div>
                    )
                }
            </RadioGroup>
        </FormControl>
    );
}

export default SearchGlobalType;