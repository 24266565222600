import { createContext, useContext, useState } from "react";
import { ParentInterface } from '@/types';
import { PrivacyOptions } from "@/types/canvas";

interface SaveTemplateProviderInterface extends ParentInterface { }

interface SaveTemplateContextInterface {
    marketList: MarketData[];
    setMarketList: (value: MarketData[]) => void

    privacyOption: PrivacyOptions;
    setPrivacyOption: (value: PrivacyOptions) => void

    selectedCompany: string[];
    setCompanySelected: (value: string[]) => void;

    restricted: string[];
    setRestricted: (value: string[]) => void;

    selectedConstraint: number[];
    setSelectedConstraint: (value: number[]) => void;
}

const saveContext = createContext<SaveTemplateContextInterface>(
    {} as SaveTemplateContextInterface
);

type MarketData = {
    company: {
        id: number,
        name: string
    },
    markets: {
        id: number,
        name: string,
        StoreID: number
    }[];
}

export const SaveTemplateProvider: React.FC<SaveTemplateProviderInterface> = ({ children }) => {
    const [marketList, setMarketList] = useState<MarketData[]>([]);
    const [privacyOption, setPrivacyOption] = useState<PrivacyOptions>("private");
    const [selectedCompany, setCompanySelected] = useState<string[]>([]);
    const [restricted, setRestricted] = useState<string[]>([]);
    const [selectedConstraint, setSelectedConstraint] = useState<number[]>([]);

    return (
        <saveContext.Provider
            value={{
                marketList,
                setMarketList,

                privacyOption,
                setPrivacyOption,

                selectedCompany,
                setCompanySelected,

                restricted,
                setRestricted,

                selectedConstraint,
                setSelectedConstraint
            }}
        >
            {children}
        </saveContext.Provider>
    );
};

export const useSaveTemplate = () => {
    const context = useContext(saveContext);

    if (!context) {
        throw new Error("useModals must be used within an ModalsContext");
    }
    return context;
};
