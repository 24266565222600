import React, { useState, useRef, useEffect } from "react";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { Element } from "@/types/canvas";
import ThreeDotsIcon from "@/components/svg/ThreeDotsIcon";

interface FixedTextOptionsInterface {
	disable: boolean;
}

type FieldType = { value: string; label: React.ReactNode };
const __FIELD_OPTIONS__: Array<FieldType> = [
	{ value: 'oferta', label: 'Oferta' },
	{ value: 'ofertarelampago', label: 'Oferta Relâmpago' },
	{ value: 'ofertaimperdivel', label: 'Oferta Imperdível' },
	{ value: 'promocao', label: 'Promoção' },
	{ value: 'promocaorelampago', label: 'Promoção Relâmpago' },
	{ value: 'promocaoimperdivel', label: 'Promoção Imperdível' },
	{ value: 'liquidacao', label: 'Liquidação' },
	{ value: 'saldao', label: 'Saldão' },
	{ value: 'imperdivel', label: 'Imperdível' },
	{ value: 'superdesconto', label: 'Super Desconto' },
	{ value: 'baixouopreco', label: 'Baixou o Preço' },
];

const FixedTextOptions: React.FC<FixedTextOptionsInterface> = ({ disable }) => {
	const { state, getElementDataById } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { current } = state;

	const currentElement = getElementDataById(current.id);

	const onChange = (option: FieldType) => {
		if (current.type !== "text") return;

		const value = option.label as string;
		if (currentElement) {
			const { useUpperCase } = currentElement.attributes;
			const text = useUpperCase ? value.toUpperCase() : value;

			const newAttributes: Element = {
				...currentElement.attributes,
				textType: null,
				text: text,
				defaultText: text,
			};
			stateDispatch({
				type: "updateElement",
				payload: {
					id: newAttributes.id, attributes: newAttributes,
				},
			});
		}
	};

	const [openOptions, setOpenOptions] = useState(false);
	const modalRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLDivElement>(null);

	const handleOpenModal = () => {
		setOpenOptions((prev) => !prev);
	};

	// Adiciona um listener para cliques fora do modal
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)
				&& buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
				setOpenOptions(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [modalRef, buttonRef]);

	return (
		<div className='relative w-fit'>
			<div ref={buttonRef} onClick={handleOpenModal} className={`${disable ? "cursor-not-allowed bg-zinc-100" : "cursor-default"} p-2 border border-zinc-300 rounded-md hover:bg-zinc-100`}>
				<ThreeDotsIcon color="stroke-zinc-500" />
			</div>

			{
				openOptions && current.type === "text" && (
					<div ref={modalRef} className="absolute right-0 z-99 overflow-hidden bg-white border rounded-lg">
						<ul className="w-60 divide-y rounded-lg h-60 overflow-auto">
							{
								__FIELD_OPTIONS__.map(el => {
									return (
										<li
											key={el.value}
											className="p-2 px-4 hover:bg-zinc-100 cursor-pointer"
											onClick={() => onChange(el)}
										>
											{el.label}
										</li>
									);
								})
							}
						</ul>
					</div>
				)
			}
		</div>
	);
};

export default FixedTextOptions;
