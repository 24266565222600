import React, { useState } from 'react';
import { useCanvasProducts } from '@/contexts/editor/CanvasProductsContext';
import DropdownButton from '@/components/buttons/DropdownButton';
import ProductSearchModal from '../modals/products/search/ProductSearchModal';
import { useModals } from '@/contexts/modals/ModalsContext';
import MouseOverPopover from '../buttons/MouseOverPopover';

interface QueueProductDropdownInterface {
}

const QueueProductDropdown: React.FC<QueueProductDropdownInterface> = () => {
    const { products, deleteProduct } = useCanvasProducts();
    const selectProducts: Array<any> = products.map(p => ({ label: p.description, data: p }));

    const { setSearchProductModal } = useModals();

    const onAddMore = () => {
        // setIsProductModalOpen(true);
        setSearchProductModal(true);
    }

    return (
        <React.Fragment>
            {/* <ProductsSearchModal isOpen={isProductModalOpen}
                onClose={() => setIsProductModalOpen(false)} /> */}
            <ProductSearchModal />
            <MouseOverPopover popoverText='Salve na lista os produtos que quer usar nos cartazes.' >
                <span className='rounded-full p-1 px-3 cursor-help shadow-md text-zinc-500' >?</span>
            </MouseOverPopover>
            <DropdownButton title="Adicionar Produtos" items={selectProducts} showBadge
                onClickFooter={onAddMore} footerLabel='Adicionar mais' optionButton={{ label: 'Remover', onClick: deleteProduct }} />
        </React.Fragment>
    )
}

export default QueueProductDropdown;
