import React from 'react';
import { useCanvas } from "@/contexts/editor/CanvasContext";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import { DynamicTextConfigButton } from '@/components/editor/sidebar/partials/TextHeader';

interface IProductHeader {
	handleAddProduct: () => void
}

const ProductHeader: React.FC<IProductHeader> = ({ handleAddProduct }) => {
	const {
		state,
		isEditing
	} = useCanvas();

	const { products } = useCanvasProducts();

	return (
		<div className="flex-1 inline-flex list-none mx-3 w-full">
			<div className="flex flex-col w-full">
				<div className="flex flex-row justify-between items-center w-full border-b-2 py-2 px-4 xl:px-10">
					<span className="font-bold text-2xl">Produtos</span>
					<div>
						{
							isEditing ?
								<>
									<AddProductButton onClick={handleAddProduct} />
								</>
								: (
									<>
										<DynamicTextConfigButton />
										<span className="cursor-default text-md font-semibold no-text-highlight ml-5" >
											{(products.length)}/{state.products.ids.length}
										</span>
									</>
								)
						}
					</div>
				</div>
			</div>
		</div>
	)
};

export default ProductHeader;

function AddProductButton({ onClick }: { onClick: () => void }) {
	const buttonClasses = "ml-auto w-28 px-4 bg-orange-500 hover:bg-orange-400 " +
		"text-center text-white rounded-lg cursor-pointer " +
		"py-2 duration-300";

	return (
		<button onClick={onClick} className={buttonClasses}>
			Novo
		</button>
	)
}
